import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, Paper } from "@material-ui/core";

import {
  getMyAccount,
  createMyAccount,
  updateMyAccount,
  updateMyResume,
  uploadMyId,
  getMyInvestorAccount,
  createMyInvestorAccount,
  updateMyInvestorAccount,
  uploadAvatar,
  updateName,
} from "./actions";

import InstituteAdminForm from "./FormComponents/InstituteAdmin";
import InvestorForm from "./FormComponents/Investor";
import RecruiterForm from "./FormComponents/Recruiter";
import StudentForm from "./FormComponents/Student";

import "./style.css";

class Account extends Component {
  state = {
    studentBio: null,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };
    if (
      props.myAccount.account &&
      props.myAccount.account.profile &&
      state.studentBio === null
    ) {
      newState["studentBio"] = props.myAccount.account.profile;
    }
    return newState;
  }

  componentDidMount() {
    const { userAuth, getMyAccount, getMyInvestorAccount } = this.props;
    if (userAuth.user && userAuth.user.user_type === "student") {
      getMyAccount();
    } else if (userAuth.user && userAuth.user.user_type === "investor") {
      getMyInvestorAccount();
    }
  }

  onStudentBioChange = (bio) => {
    this.setState({ studentBio: bio });
  };

  submitStudent = (data) => {
    const { myAccount, createMyAccount, updateMyAccount } = this.props;
    var formData = new FormData();
    const studentInfo = { ...data };

    if (myAccount.account && myAccount.account.id) {
      const updatedField = {};
      for (let keys in myAccount.account) {
        if (
          studentInfo[keys] !== myAccount.account[keys] &&
          studentInfo[keys] !== undefined
        ) {
          updatedField[keys] = true;
        }
      }
      if (updatedField.institute) {
        studentInfo.institute_id = studentInfo.institute.value;
        delete studentInfo.institute;
        formData.append("institute_id", studentInfo.institute_id);
      }
      if (updatedField.resume) formData.append("resume", studentInfo.resume[0]);
      if (updatedField.identification_card)
        formData.append(
          "identification_card",
          studentInfo.identification_card[0]
        );
      if (updatedField.enable_search)
        formData.append("enable_search", studentInfo.enable_search);
      if (updatedField.available_for_funding)
        formData.append(
          "available_for_funding",
          studentInfo.available_for_funding
        );
      if (updatedField.proof_of_reg)
        formData.append("proof_of_reg", studentInfo.proof_of_reg[0]);
      if (this.state.studentBio) {
        formData.append("profile", JSON.stringify(this.state.studentBio));
      }
      updateMyAccount(formData);
    } else {
      if (studentInfo.institute) {
        studentInfo.institute_id = studentInfo.institute.value;
        delete studentInfo.institute;
        formData.append("institute_id", studentInfo.institute_id);
      }
      if (studentInfo.resume) formData.append("resume", studentInfo.resume[0]);
      if (studentInfo.identification_card)
        formData.append(
          "identification_card",
          studentInfo.identification_card[0]
        );
      if (studentInfo.enable_search)
        formData.append("enable_search", studentInfo.enable_search);
      if (studentInfo.available_for_funding)
        formData.append(
          "available_for_funding",
          studentInfo.available_for_funding
        );
      if (studentInfo.proof_of_reg)
        formData.append("proof_of_reg", studentInfo.proof_of_reg);
      if (this.state.studentBio) {
        formData.append("profile", JSON.stringify(this.state.studentBio));
      }
      createMyAccount(formData);
    }
  };

  submitInvestor = (data) => {
    const {
      myAccount,
      createMyInvestorAccount,
      updateMyInvestorAccount,
    } = this.props;
    var formData = new FormData();
    const investorInfo = { ...data };
    if (myAccount.account && myAccount.account.id) {
      for (let keys in myAccount.account) {
        if (
          investorInfo[keys] !== myAccount.account[keys] &&
          investorInfo[keys] !== undefined &&
          keys !== "identification_card"
        ) {
          formData.append(keys, investorInfo[keys]);
        }
      }
      updateMyInvestorAccount(formData);
    } else {
      if (investorInfo.identification_card)
        formData.append(
          "identification_card",
          investorInfo.identification_card[0]
        );
      formData.append("address", investorInfo.address);
      formData.append("city", investorInfo.city);
      formData.append("state", investorInfo.state);
      formData.append("zip", investorInfo.zip);
      createMyInvestorAccount(formData);
    }
  };

  submitRecruiter = (data) => {};

  submitInstituteAdmin = (data) => {};

  renderFormForUserType = () => {
    const { myAccount, userAuth, uploadAvatar, updateName } = this.props;
    if (!userAuth.user)
      return (
        <Typography variant="h6">
          You don't have access to this page.
        </Typography>
      );
    switch (userAuth.user.user_type) {
      case "student":
        return (
          <StudentForm
            initialValues={myAccount.account}
            onSubmit={this.submitStudent}
            user={userAuth.user}
            uploadAvatar={uploadAvatar}
            updateName={updateName}
            onStudentBioChange={this.onStudentBioChange}
            studentBio={this.state.studentBio}
          />
        );
      case "investor":
        return (
          <InvestorForm
            initialValues={myAccount.account}
            onSubmit={this.submitInvestor}
            uploadAvatar={uploadAvatar}
            updateName={updateName}
          />
        );
      case "recruiter":
        return (
          <RecruiterForm
            initialValues={myAccount.account}
            onSubmit={this.submitRecruiter}
            uploadAvatar={uploadAvatar}
            updateName={updateName}
          />
        );
      case "instituteadmin":
        return (
          <InstituteAdminForm
            initialValues={myAccount}
            onSubmit={this.submitInstituteAdmin}
            uploadAvatar={uploadAvatar}
            updateName={updateName}
          />
        );
      default:
        return (
          <Typography variant="h4">
            You don't have access to this page.
          </Typography>
        );
    }
  };

  render() {
    return (
      <div className="account-container">
        <Paper className="account-form">
          <Typography variant="h4">Profile</Typography>
          {this.renderFormForUserType()}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myAccount: state.accountReducer.myAccount,
    userAuth: state.loginReducer.userAuth,
  };
};

export default connect(mapStateToProps, {
  getMyAccount,
  createMyAccount,
  updateMyAccount,
  updateMyResume,
  uploadMyId,
  getMyInvestorAccount,
  createMyInvestorAccount,
  updateMyInvestorAccount,
  uploadAvatar,
  updateName,
})(Account);
