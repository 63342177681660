import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  DialogActions,
  Button,
  Fab,
  DialogContent,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";

import CampaignComponent from "../CommonComponents/Campaign";
import SharerComponent from "../CommonComponents/SharerComponent";
import { toast } from "react-toastify";

import { Share as ShareIcon } from "@material-ui/icons";

import {
  getCampaignById,
  initiateTransaction,
  closeStripePayment,
} from "./actions";
import { initiateLogin } from "../../components/Login/actions";

import Stripe from "../../components/Stripe";

import cards_icon from "../../resources/images/cards.png";
import paypal_icon from "../../resources/images/paypal.png";
import crypto_icon from "../../resources/images/crypto.png";

class PublicCampaign extends Component {
  state = {
    paymentGateway: null,
    openPaymentGateway: false,
    amount: null,
    sharerOpen: false,
    tncAccepted: false,
    termsDialog: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.stripeInitiate || props.paypalApproved) {
      return { ...state, openPaymentGateway: false };
    }
  }

  componentDidMount() {
    this.props.getCampaignById(this.props.match.params.id);
  }

  openSelectPaymentGateway = () => {
    this.setState({ openPaymentGateway: true });
  };

  closeSelectPaymentGateway = () => {
    this.setState({ openPaymentGateway: false });
  };

  selectPaymentGateway = (gateway) => {
    const { campaign } = this.props;
    const { amount } = this.state;
    this.setState({ paymentGateway: gateway });
    this.props.initiateTransaction({
      collection_id: campaign.id,
      value: amount,
      currency: campaign.currency,
      payment_gateway: gateway,
    });
  };

  selectPaypal = () => {
    const { amount } = this.state;
    let { campaign, initiateTransaction } = this.props;

    this.setState({ paymentGateway: "paypal" });
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: campaign.currency,
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          console.log("--onApprove");
          return actions.order.capture().then(function (details) {
            initiateTransaction({
              collection_id: campaign.id,
              value: amount,
              currency: campaign.currency,
              payment_gateway: "paypal",
              transaction_info: details,
            });
          });
        },
        onCancel: (data) => {
          toast.error("Payment cancelled");
        },
        onError: (err) => {
          console.error(err);
          toast.error("Error with Payment please try again");
        },
      })
      .render("#paypal-button-container");
    document.getElementById("paypal_button").style.display = "none";
  };

  onPayClick = (amount) => {
    if (this.props.userAuth.user && this.props.userAuth.user.id) {
      this.setState({ amount: amount });
      this.openSelectPaymentGateway();
    } else {
      this.props.initiateLogin();
    }
  };

  closeCardPayment = () => {
    const { getCampaignById, closeStripePayment, stripeStatus } = this.props;
    if (stripeStatus === "success") {
      closeStripePayment();
      getCampaignById(this.props.match.params.id);
    } else if (stripeStatus === "failed") {
      closeStripePayment();
    }
  };

  render() {
    const { campaign, stripeInitiate, transaction, stripePromise } = this.props;
    const { sharerOpen, tncAccepted, termsDialog } = this.state;
    return (
      <div style={{ padding: "30px" }}>
        <SharerComponent
          open={sharerOpen}
          onClose={() => {
            this.setState({ sharerOpen: false });
          }}
          link={"/publiccampaign/" + this.props.match.params.id}
        />
        <Dialog
          onClose={this.closeCardPayment}
          open={stripeInitiate}
          disableBackdropClick
        >
          <DialogTitle>Pay with your card</DialogTitle>
          <div style={{ width: "540px", padding: "30px" }}>
            <Elements stripe={stripePromise}>
              <Stripe
                clientSecret={
                  transaction ? transaction.transaction_info.client_secret : ""
                }
              />
            </Elements>
          </div>
        </Dialog>
        <Dialog
          onClose={this.closeSelectPaymentGateway}
          open={this.state.openPaymentGateway}
          disableBackdropClick
        >
          <DialogTitle>Select to proceed</DialogTitle>
          <List>
            {campaign && !campaign.is_digital_currency && (
              <ListItem
                button
                onClick={() =>
                  tncAccepted
                    ? this.selectPaymentGateway("stripe")
                    : toast.error("Please accept T&C to proceed.")
                }
              >
                <img src={cards_icon} alt="card_icon" height="18px" /> &nbsp;
                Debit/Credit Card
              </ListItem>
            )}
            <ListItem
              button
              onClick={() =>
                tncAccepted
                  ? this.selectPaymentGateway("coinbase")
                  : toast.error("Please accept T&C to proceed.")
              }
            >
              <img src={crypto_icon} alt="crypto_icon" height="18px" /> &nbsp;
              &nbsp; Crypto Currency
            </ListItem>
            {campaign && !campaign.is_digital_currency && (
              <ListItem
                id="paypal_button"
                button
                onClick={() =>
                  tncAccepted
                    ? this.selectPaypal()
                    : toast.error("Please accept T&C to proceed.")
                }
              >
                <img src={paypal_icon} alt="paypal_icon" height="18px" /> &nbsp;
                &nbsp; Paypal
              </ListItem>
            )}
          </List>

          <div
            id="paypal-button-container"
            style={{ padding: "10px", paddingBottom: "0px" }}
          />
          <div style={{ padding: "10px", backgroundColor: "#e1e1e1" }}>
            <input
              id="payment_tnc"
              type="checkbox"
              onClick={(e) => {
                this.setState({ tncAccepted: e.target.checked });
              }}
            />{" "}
            I agree to{" "}
            <span
              style={{
                cursor: "pointer",
                fontWeight: "600",
                textDecoration: "underline",
              }}
              onClick={() => {
                this.setState({ termsDialog: true });
              }}
            >
              Terms & Conditions
            </span>
            .
          </div>
          <DialogActions>
            <Button onClick={this.closeSelectPaymentGateway} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={termsDialog}
          onClose={() => {
            this.setState({ termsDialog: false });
          }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Terms of Service Alumna transfers
          </DialogTitle>
          <DialogContent>
            <embed
              src={"/cdn/Terms of Service Alumna transfers.pdf"}
              view="FitH"
              width="100%"
              height="600px"
            ></embed>
          </DialogContent>
        </Dialog>

        <Paper style={{ padding: "30px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h3">
                {campaign && campaign.collection_type === "public_campaign"
                  ? "Project"
                  : ""}
                {campaign && campaign.collection_type === "student_campaign"
                  ? "Campaign"
                  : ""}{" "}
                &nbsp;
                {campaign && (
                  <Fab
                    size="medium"
                    color="secondary"
                    onClick={() => {
                      this.setState({ sharerOpen: true });
                    }}
                  >
                    <ShareIcon />
                  </Fab>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "-webkit-right" }}>
              {campaign && campaign.collection_type === "student_campaign" && (
                <Link to={"/studentprofile/" + campaign.student_id}>
                  <div
                    style={{
                      width: "80px",
                      textAlign: "center",
                    }}
                  >
                    <Paper
                      style={{
                        padding: "2px 10px",
                        border: "solid 2px #b6f0d6",
                      }}
                    >
                      <Typography variant="caption">PROFILE</Typography>
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: "auto",
                          borderRadius: "50%",
                          overflow: "hidden",
                          boxShadow: "1px 2px 5px grey",
                          backgroundImage: `url(${campaign.avatar_media_url})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <Typography variant="subtitle1">
                        {campaign.firstname}
                      </Typography>
                    </Paper>
                  </div>
                </Link>
              )}
            </Grid>
            <Grid item xs={12}>
              {campaign && (
                <CampaignComponent
                  campaign={campaign}
                  onPayClick={
                    campaign.available_for_funding &&
                    campaign.is_active &&
                    new Date(campaign.start_date).getTime() <=
                      new Date().getTime() &&
                    new Date(campaign.end_date).getTime() > new Date().getTime()
                      ? this.onPayClick
                      : null
                  }
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: "20px" }}
            ></Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.publicCampaign.campaign,
    userAuth: state.loginReducer.userAuth,
    transaction: state.publicCampaign.transaction,
    stripeInitiate: state.publicCampaign.stripeInitiate,
    stripePromise: state.publicCampaign.stripePromise,
    stripeStatus: state.publicCampaign.stripeStatus,
    paypalApproved: state.publicCampaign.paypalApproved,
  };
};

export default connect(mapStateToProps, {
  getCampaignById,
  initiateTransaction,
  initiateLogin,
  closeStripePayment,
})(PublicCampaign);
