import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Navbar from "./CommonComponents/Navbar";
import Footer from "./CommonComponents/Footer";

import LoginComponent from "../components/Login";
import Blog from "../components/Blog";
import StudentInfo from "../components/StudentInfo";
import HowItWorks from "../components/HowItWorks";
import WhatIsBlockChain from "../components/whatisblkchain";
import { updateMe } from "../components/Login/actions";

import InternalServerError from "./CommonComponents/InternalServerError";
import EmailActivated from "./CommonComponents/EmailActivated";

import HomePage from "./HomePage";
import Login from "./Login";
import Dashboard from "./Dashboard";
import PublicCampaign from "./PublicCampaign";
import CampaignList from "./CampaignList";
import PayToCampaign from "./PayToCampaign";
import PayToStudent from "./PayToStudent";
import Profile from "./Profile";
import Account from "./Account";
import CreateCampaign from "./CreateCampaign";

import ScrollToTop from "../utils/scrollToTop";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateMe());
  });

  let navbarItems = [
    {
      title: "How it Works",
      link: "/howitworks",
    },
    {
      title: "Browse Campaigns",
      link: "/campaigns/student",
    },
    {
      title: "Student Guide",
      link: "/studentinfo",
    },
    {
      title: "Impact Investor Guide",
      link: "/techfaqs",
    },
  ];

  return (
    <Router>
      <ScrollToTop />
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LoginComponent />
        <Navbar internalLinks={navbarItems} />
        <div style={{ padding: "34px" }} />
        <Switch>
          <Route path="/login" component={Login}></Route>
          <Route path="/dashboard" component={Dashboard}></Route>
          <Route path="/publiccampaign/:id" component={PublicCampaign}></Route>
          <Route path="/campaigns/:type?" component={CampaignList}></Route>
          <Route path="/paytocampaign" component={PayToCampaign}></Route>
          <Route path="/paytostudent" component={PayToStudent}></Route>
          <Route path="/studentprofile/:id" component={Profile}></Route>
          <Route path="/profile" component={Account}></Route>
          <Route path="/campaign" component={CreateCampaign}></Route>
          <Route path="/emailActivated" component={EmailActivated}></Route>
          <Route path="/blog" component={Blog}></Route>
          <Route path="/studentinfo" component={StudentInfo}></Route>
          <Route path="/howitworks" component={HowItWorks}></Route>
          <Route path="/techfaqs" component={WhatIsBlockChain}></Route>
          <Route
            path="/internalServerError"
            component={InternalServerError}
          ></Route>
          <Route path="/" component={HomePage}></Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
