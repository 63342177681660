import { FETCH_HOME } from "../../constants";

import axios from "axios";

export const getHomePageData = () => {
  return (dispatch) => {
    axios
      .get("https://reqres.in/api/users")
      .then((res) => {
        dispatch({ type: FETCH_HOME, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};