import React, { useState } from "react";
import { connect } from 'react-redux';
import { Button, Typography, Grid, Dialog } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import image2 from "../../resources/images/student_info.jpeg";

import bridget from "../../resources/student_page/bridget.jpeg";
import grant from "../../resources/student_page/grant.jpeg";
import holly from "../../resources/student_page/holly.jpeg";
import pierre from "../../resources/student_page/pierre.jpeg";
import ref from "../../resources/student_page/ref.jpeg";
import saf from "../../resources/student_page/saf.jpeg";
import FundingForm from "./FundingForm";
import { toggleFundingForm} from './actions';

import "./studentinfo.css";


function StudentInfo ({toggleFundingForm}) {
  const [dialogVideo, setDialogVideo] = useState(null);
  const handleClose = () => {
    setDialogVideo(null);
  };
  return (
    <div>
      <FundingForm />
      <Dialog onClose={handleClose} open={dialogVideo}>
        <video preload="auto" controls>
          <source
            src={"/cdn/" + dialogVideo + ".mp4"}
            type="video/mp4"
          />
          Your browser does not support HTML video.
        </video>
      </Dialog>

      <div
        style={{
          backgroundImage: `url(${image2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "92vh",
          textAlign: "center",
          color: "white",
          textShadow: "1px 2px 3px #000",
        }}
      >
        <Typography
          variant="h2"
          style={{
            paddingTop: "14%",
            fontFamily: "fantasy",
          }}
        >
          NO BANK. NO PROBLEM!
        </Typography>
        <div
          style={{
            maxWidth: "74px",
            margin: "auto",
            cursor: "pointer",
            borderRadius: "50%",
            textAlign: "center",
            color: "white",
            padding: "20px",
            background: "rgb(50,50,50, 0.4)",
            boxShadow: "1px 0px 2px grey",
          }}
          onClick={() => {
            var elem = document.getElementById("faq_section");
            elem.scrollIntoView({ block: "start", behavior: "smooth" });
          }}
        >
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Typography
        id="faq_section"
        variant="h5"
        style={{ padding: "40px 200px 20px 200px", textAlign: "center" }}
        color="primary"
      >
        Alumna provides creative products for qualifying students to be able to
        access funds through digital asset leasing. Alumna’s innovative products
        also allow students to access funding without the limitations of
        required collateral experienced through banks.
      </Typography>
      <Typography
        variant="h6"
        style={{
          padding: "10px 273px",
          textAlign: "center",
          color: "darkblue",
        }}
      >
        The Alumna Products Help Students at all Stages of the Education Life
        Cycle from raising money for Tuition, to accessing money while getting
        educated without an income, to starting employment after Graduation.
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ padding: "10px 250px", textAlign: "center" }}
      >
        Get to know the characters below and how applying for them can help you
        fulfill your Graduation goals:
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ padding: "10px 250px", textAlign: "center" }}
      >
        These products are for all Registered Students
      </Typography>
      <Grid container>
        <Grid className="student_items" item xs={6}>
          <Typography variant="body1">
            <b>Meet Grant</b>
          </Typography>
          <img
            className="image_click"
            src={grant}
            alt="grant"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("grant");
            }}
          />
          <Typography variant="body1">
            Grant and Crowd Funding Product
          </Typography>
        </Grid>
        <Grid className="student_items" item xs={6}>
          <Typography variant="body1">
            <b>Meet Pierre</b>
          </Typography>
          <img
            className="image_click"
            src={pierre}
            alt="pierre"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("pierre");
            }}
          />
          <Typography variant="body1">
            Peer to Peer (P2P) Lending Product
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle2"
        style={{ padding: "50px 250px 30px", textAlign: "center" }}
      >
        The Below Funding Products Require Qualifying Criteria
      </Typography>

      <Grid container>
        <Grid className="student_items" item xs={3}>
          <Typography variant="body1">
            <b>Meet SAFIYA (SAF)</b>
          </Typography>
          <img
            className="image_click"
            src={saf}
            alt="saf"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("saf");
            }}
          />
          <Typography variant="body1">SAF = Student Asset Finance</Typography>
        </Grid>
        <Grid className="student_items" item xs={3}>
          <Typography variant="body1">
            <b>Meet Bridget</b>
          </Typography>
          <img
            className="image_click"
            src={bridget}
            alt="bridget"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("bridget");
            }}
          />
          <Typography variant="body1">Bridget = Bridge Finance</Typography>
        </Grid>
        <Grid className="student_items" item xs={3}>
          <Typography variant="body1">
            <b>Meet Ref</b>
          </Typography>
          <img
            className="image_click"
            src={ref}
            alt="ref"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("ref");
            }}
          />
          <Typography variant="body1">Ref = Refinance</Typography>
        </Grid>
        <Grid className="student_items" item xs={3}>
          <Typography variant="body1">
            <b>Meet Holly</b>
          </Typography>
          <img
            className="image_click"
            src={holly}
            alt="holly"
            width="250px"
            style={{ padding: "10px" }}
            onClick={() => {
              setDialogVideo("holly");
            }}
          />
          <Typography variant="body1">Holly = Holiday Finance</Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href="/cdn/Funding Requirements Guideline.pdf"
        >
          Funding Requirements Guideline
        </Button>
      </div>
      <br />
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          // href="mailto:funding@alumna.co.za"
          onClick={toggleFundingForm}
        >
          Apply for Funding
        </Button>
      </div>
      <br />
      <br />
    </div>
  );
}

export default connect(null, {
  toggleFundingForm,
})(StudentInfo);

