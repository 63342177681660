import { GET_STUDENT_PROFILE } from "../../constants";

import axios from "axios";

export const getStudentProfile = (id) => {
  return (dispatch) => {
    axios
      .get("student/profile/"+id)
      .then((res) => {
        dispatch({ type: GET_STUDENT_PROFILE, data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
