import { OPEN_LOGIN, LOGIN_RESPONSE, SIGNUP_RESPONSE } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";

export const initiateLogin = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOGIN, data: true });
  };
};

export const closeLogin = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOGIN, data: false });
  };
};

export const tryLogin = (username, password) => {
  return (dispatch) => {
    axios
      .post("/users/login", {
        email: username,
        password: password,
      })
      .then((res) => {
        dispatch({ type: LOGIN_RESPONSE, data: res.data.data });
        toast.info("Signin Successful");
        if (!(res.data.data.user.userInfo && res.data.data.user.userInfo.id)) {
          setTimeout(function () {
            toast.dark("Please complete your account, to use all services");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const updateMe = () => {
  return (dispatch) => {
    axios
      .get("/users/me")
      .then((res) => {
        dispatch({ type: LOGIN_RESPONSE, data: res.data.data });
      })
      .catch((err) => {
        dispatch({ type: LOGIN_RESPONSE, data: {} });
      });
  };
};

export const doSignup = (user) => {
  return (dispatch) => {
    axios
      .post("/users/signup", {
        ...user,
      })
      .then((res) => {
        dispatch({ type: SIGNUP_RESPONSE, data: res.data.data });
        toast.info("Successful");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    axios
      .post("/users/logout", {})
      .then((res) => {
        dispatch({ type: SIGNUP_RESPONSE, data: {} });
        toast.info("Thanks for using our services.");
        window.location = "/";
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const getOTP = (email) => {
  return (dispatch) => {
    axios
      .post("/users/getOTP", { email })
      .then((res) => {
        toast.info(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const submitPasswordWithOTP = (data) => {
  return (dispatch) => {
    axios
      .post("/users/resetpassword", data)
      .then((res) => {
        toast.info(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
