import axios from "axios";

export default function () {
  axios.defaults.baseURL =
    window.location.hostname === "localhost"
      ? "http://localhost:3030/v1"
      : "/v1";
  // axios.defaults.baseURL = "https://app.alumna.co.za/v1";
  // axios.defaults.baseURL = "https://alumna.co.za/v1";
  axios.defaults.withCredentials = true;
  //   axios.defaults.headers.common["Authorization"] = "AUTH TOKEN";
  //   axios.defaults.headers.post["Content-Type"] = "application/json";

  //   axios.interceptors.request.use(
  //     (request) => {
  //       console.log(request);
  //       // Edit request config
  //       return request;
  //     },
  //     (error) => {
  //       console.log(error);
  //       return Promise.reject(error);
  //     }
  //   );

  //   axios.interceptors.response.use(
  //     (response) => {
  //       console.log(response);
  //       // Edit response config
  //       return response;
  //     },
  //     (error) => {
  //       console.log(error);
  //       return Promise.reject(error);
  //     }
  //   );
}
