import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid, Typography, Paper } from "@material-ui/core";
import axios from "axios";
import {
  renderTextField,
  autoCompleteSelect,
} from "../../../utils/reduxFormComponents";

const CreateCampaignForm = (props) => {
  const { handleSubmit, pristine, reset, submitting, initialValues } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">Title</Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="title"
            component={renderTextField}
            label="Title"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">Description</Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="description"
            component={renderTextField}
            label="Description"
            fullWidth
            multiline
            rows={4}
            rowsMax={4}
          />
        </Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">Youtube</Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="youtube_id"
            component={renderTextField}
            label="Youtube"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">Goal</Typography>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "12px" }}>
          {initialValues.currency ? (
            <Paper variant="outlined" style={{ padding: "7px 15px" }}>
              {initialValues.currency}
            </Paper>
          ) : (
            <Field
              name="currency"
              component={autoCompleteSelect}
              loadOptions={(input, callback) => {
                if (input === "" || input === null) {
                  callback([]);
                } else {
                  axios
                    .get("common/searchcurrency?key=" + input)
                    .then((res) => {
                      callback(res.data.data.currency || []);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
              placeholder="Currency"
              label="Currency"
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <Field
            id="goal-amount"
            name="goal"
            component={renderTextField}
            label="Goal"
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: 50000,
              },
            }}
            fullWidth
            onChange={(e) => {
              if (Number(e.target.value) > 50000) {
                e.target.value = 50000;
              }
              if (Number(e.target.value) < 0) {
                e.target.value = 0;
              }
            }}
          />
        </Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">Start Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="start_date"
            component={renderTextField}
            label="Start Date"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} className="input-field">
          <Typography variant="subtitle2">End Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="end_date"
            component={renderTextField}
            label="End Date"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={pristine || submitting || initialValues.id}
            onClick={reset}
            fullWidth
          >
            Clear Values
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || submitting}
            fullWidth
          >
            {initialValues.id ? "Update" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default reduxForm({
  form: "CreateCampaignForm", // a unique identifier for this form
})(CreateCampaignForm);
