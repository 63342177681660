import { GET_STUDENT_PROFILE } from "../../constants";

let initialState = {
  profile: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_PROFILE:
      return { ...state, profile: action.data.account };
    default:
      return state;
  }
};
