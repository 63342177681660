import {
  FETCH_HOME,
  FETCH_STUDENT_CAMPAIGN,
  TESTIMONIALS,
} from "../../constants";

let initialState = {
  pageFeed: [],
  testimonials: [],
  studentCampaigns: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME:
      return { ...state, pageFeed: action.data.campaigns };
    case FETCH_STUDENT_CAMPAIGN:
      return { ...state, studentCampaigns: action.data.campaigns };
    case TESTIMONIALS:
      return { ...state, testimonials: action.data.testimonials };
    default:
      return state;
  }
};
