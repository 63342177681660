import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography, Paper, Button } from "@material-ui/core";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
// import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";

import {
  getPublicCampaign,
  getStudentCampaigns,
  getTestimonials,
} from "./actions";
import { initiateLogin } from "../../components/Login/actions";
import TitleBackground from "../../resources/images/bg1.jpg";
// import ActionBackground from "../../resources/images/actionbg.png";

import CampaignCard from "./components/CampaignCard";

// import img1 from "../../resources/lpc/1.jpg";
// import img2 from "../../resources/lpc/2.jpg";
// import img3 from "../../resources/lpc/3.jpg";
// import img4 from "../../resources/lpc/4.jpg";
// import img5 from "../../resources/lpc/5.jpg";
// import img6 from "../../resources/lpc/6.jpg";

import "./style.css";

// const crouselImages = [img1, img2, img3, img4, img5, img6];

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.firstDiv = React.createRef();
  }
  componentDidMount() {
    this.props.getPublicCampaign();
    this.props.getStudentCampaigns();
    this.props.getTestimonials();
  }

  render() {
    const { pageFeed, testimonials } = this.props;
    pageFeed.length = 4;
    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            height: "92vh",
            textAlign: "center",
            color: "white",
            // textShadow: "2px 2px #5b5b5b",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            style={{
              position: "absolute",
              backgroundImage: `url(${TitleBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "92vh",
              zIndex: "-1",
              filter: "blur(2px)",
            }}
          >
            {/* <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={60}
              totalSlides={crouselImages.length}
              isPlaying={true}
              visibleSlides={1}
              interval={10000}
              hasMasterSpinner={crouselImages.length === 0}
            >
              <Slider>
                {crouselImages.map((img, i) => (
                  <Slide ref={i} key={i}>
                    <div
                      key={"img" + i}
                      style={{
                        backgroundImage: `url(${img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "92vh",
                      }}
                    />
                  </Slide>
                ))}
              </Slider>
            </CarouselProvider> */}
          </div>
          <div
            style={{
              maxWidth: "950px",
              margin: "450px auto 100px",
              padding: "40px 20px 20px",
            }}
          >
            <Typography variant={"h3"} style={{ fontFamily: "fantasy" }}>
              A new way to fund Global Education Markets
            </Typography>

            <Link to="/howitworks">
              <Button
                color="secondary"
                variant="contained"
                size="large"
                style={{ margin: "20px", fontWeight: "bold" }}
              >
                Learn More
              </Button>
            </Link>
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ margin: "20px", fontWeight: "bold" }}
              onClick={() => {
                this.props.initiateLogin();
              }}
            >
              Get Started
            </Button>
          </div>
          {/* Tag */}
        </Grid>
        <Grid item xs={12} style={{ padding: "30px" }}>
          <div
            style={{
              textAlign: "center",
              padding: "30px 50px",
              color: "darkblue",
              // background: "#6bbefa",
              // textShadow: "1px 1px 5px #5b5b5b",
            }}
          >
            {/* <Typography
              variant={"h4"}
              style={{
                fontFamily: "fantasy",
                color: "#45ad7e",
              }}
            >
              Connecting people.
              <br />
              Growing Mutual Enrichment!
            </Typography>
            <br />
            <Typography variant={"h5"}>
              Alumna represents an ecosystem of collaboration and mutual
              enrichment. Your investment can boost the opportunities of young
              talented individuals that want to acquire the best education and
              technical skills to build their local communities. All this while
              earning a positive economic return and creating a positive social
              impact.
            </Typography> */}
            <Typography
              variant={"h5"}
              style={{
                fontFamily: "inherit",
                padding: "25px 150px 25px 150px",
              }}
            >
              <span style={{
                color: "darkgreen",
              }}>Alumna is a DeFi platform for those who simply want to use
              different methods to fund their education experience. </span>
              Favoring unobstructed access and removal of financial barriers to global education.
            </Typography>
          </div>
          <hr style={{ width: "75%" }} />
          <br /> {/* Top Campaigns */}
          <Typography variant={"h5"}>Invest in our community</Typography>
          <Typography variant={"h4"}>Projects Open for Crowd Sales</Typography>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={
              window.innerHeight > 700
                ? window.innerHeight / 5.38
                : window.innerHeight / 5.38 + window.innerHeight / 25
            }
            totalSlides={pageFeed.length}
            isPlaying={false}
            visibleSlides={4}
            interval={10000}
            hasMasterSpinner={pageFeed.length === 0}
          >
            <Slider style={{ padding: "30px" }}>
              {pageFeed.map((item, i) => {
                return (
                  <Slide ref={i} key={i}>
                    <CampaignCard campaign={item} />
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
          <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
            <Link to="/campaigns/public">
              <Button variant="contained" color="primary">
                Browse Crowd Sale Projects
              </Button>
            </Link>
          </div>
          <br />
          <hr style={{ width: "75%" }} />
          <br />
          <div
            style={{
              textAlign: "center",
              padding: "30px 350px",
              color: "darkblue",
              // background: "#ff8d6b",
              // marginTop: "30px",
              // textShadow: "1px 1px 5px #5b5b5b",
            }}
          >
            <Typography
              variant={"h4"}
              style={{
                fontFamily: "fantasy",
                color: "#45ad7e",
              }}
            >
              Make an impact while you earn
            </Typography>
            <br />
            <Typography variant={"h5"}>
              A new way of investing, favouring the financial inclusion of
              unbanked students around the world to contribute to socio economic
              growth and development.
            </Typography>
          </div>
          <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
            <Link to="/campaigns/student">
              <Button variant="contained" color="primary">
                Browse Student Campaigns
              </Button>
            </Link>
          </div>
        </Grid>

        <Grid item xs={12} style={{ padding: "30px" }}>
          {" "}
          {/* Testimonial */}
          <Typography variant={"h4"}>Testimonials</Typography>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={60}
            totalSlides={testimonials.length}
            isPlaying={true}
            visibleSlides={3}
            interval={10000}
            hasMasterSpinner={testimonials.length === 0}
          >
            <Slider>
              {testimonials.map((item, i) => {
                return (
                  <Slide ref={i} key={i}>
                    <Paper className="slider-block">
                      {/* <div className="testimonial-block">
                        <i>{item.comment}</i>
                      </div> */}
                      <Grid container>
                        <Grid item xs={6}>
                          <div className="testimonial-block">
                            <i>{item.comment}</i>
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <div
                            style={{
                              // width: "50px",
                              width: "170px",
                              height: "170px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              boxShadow: "1px 2px 5px grey",
                              backgroundImage: `url(${item.media})`,
                              backgroundSize: "cover",
                              margin: "auto",
                              border: "solid 4px white",
                            }}
                          />
                          <div className="testimonial-name">
                            <Typography variant="subtitle1">
                              {item.full_name}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ height: "280px", backgroundColor: "#fff", padding: "30px" }}
        >
          {" "}
          {/* About */}
          <Typography variant={"h4"}>Why Alumna?</Typography>
          <div style={{ textAlign: "center", padding: "50px" }}>
            <Typography variant={"h5"}>
              “Alumna’s mission is to solve the global higher education debt crisis
               within a fintech ecosystem through the digital economy”.
            </Typography>
          </div>
        </Grid>

        {/* <Grid
          id="wpf"
          item
          xs={12}
          style={{
            textAlign: "center",
            paddingTop: "70px",
            paddingBottom: "50px",
            backgroundImage: `url(${ActionBackground})`,
            backgroundSize: "cover",
            color: "white",
            textShadow: "2px 2px #5b5b5b",
          }}
        >
          <Typography variant="h4">ALUMNA WHITE PAPER & FAQ'S.</Typography>
          <Typography variant="h5">Understand Our Vision</Typography>
          <div style={{ padding: "20px" }} />
          <Button
            variant="contained"
            size="large"
            style={{ margin: "10px", textShadow: "none" }}
            color="primary"
            href="/cdn/Alumna-White-Paper-6.pdf"
          >
            <GetAppIcon /> Download Alumna White Paper
          </Button>
          <br />
          <Button
            variant="contained"
            size="large"
            style={{ margin: "10px", textShadow: "none" }}
            color="secondary"
            href="/cdn/FAQs.pdf"
          >
            <GetAppIcon /> Download FAQ's
          </Button>
          <br />
          <Link to="/techfaqs">
            <Button
              variant="contained"
              size="large"
              style={{ margin: "10px", textShadow: "none" }}
              color="secondary"
            >
              <GetAppIcon /> Technical FAQs
            </Button>
          </Link>
        </Grid> */}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageFeed: state.homeReducer.pageFeed,
    studentCampaigns: state.homeReducer.studentCampaigns,
    testimonials: state.homeReducer.testimonials,
  };
};

export default connect(mapStateToProps, {
  getPublicCampaign,
  getStudentCampaigns,
  getTestimonials,
  initiateLogin,
})(HomePage);
