import {
  FETCH_HOME,
  FETCH_STUDENT_CAMPAIGN,
  TESTIMONIALS,
} from "../../constants";

import axios from "axios";

export const getPublicCampaign = () => {
  return (dispatch) => {
    axios
      .get("/publiccampaign/campaigns")
      .then((res) => {
        dispatch({ type: FETCH_HOME, data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getStudentCampaigns = () => {
  return (dispatch) => {
    axios
      .get("/studentcampaign/campaigns")
      .then((res) => {
        dispatch({ type: FETCH_STUDENT_CAMPAIGN, data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getTestimonials = () => {
  return (dispatch) => {
    axios
      .get("/common/testimonials")
      .then((res) => {
        dispatch({ type: TESTIMONIALS, data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
