import axios from 'axios';
import { toast } from "react-toastify";
import {FUNDING_ENQUIRY_SUCCESS, FUNDING_ENQUIRY_FAIL, TOGGLE_FUNDING_FROM} from '../../constants';



export const toggleFundingForm = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_FUNDING_FROM });
  };
};

export const fundingEnquiry = (values) => async (dispatch) => {
  try {
    const response = await axios.post('/student/funding-enquiry', values, {
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: FUNDING_ENQUIRY_SUCCESS });
    toast.info(response?.data?.message || 'Funding Enquiry Submitted successfully!');
  } catch (error) {
    dispatch({ type: FUNDING_ENQUIRY_FAIL, payload: error.message });
    const msg = 'Error in submitting funding enquiry. Please try again.';
    toast.error(error.response?.data?.message || msg);
    console.warn(msg);
  }
};
