import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import { toast } from "react-toastify";

import * as SharerPlugin from "react-share";

import campaign_media from "../../resources/images/campaign_media.png";

import { createCampaign, getMyCampaign, updateCampaign } from "./actions";

import CreateCampaignForm from "./Components/CreateCampaignForm";
import CampaignComponent from "../CommonComponents/Campaign";
import "./style.css";

class CreateCampaign extends Component {
  state = {
    copyLinkBox: false,
    enableEditing: false,
  };

  componentDidMount() {
    this.props.getMyCampaign();
  }

  createCampaignClick = () => {};

  onMediaUpload = (e) => {
    let imgElement = document.getElementById("campaign-cover");
    var reader = new FileReader();

    reader.onload = function (event) {
      imgElement.src = event.target.result;
    };

    reader.readAsDataURL(e.target.files[0]);
    if (this.state.enableEditing) {
      var formData = new FormData();
      formData.append("media", e.target.files[0]);
      this.props.updateCampaign(formData);
    }
  };

  onFormSubmit = (data) => {
    let campaignData = { ...data };
    campaignData.media = document.getElementById("campaign-media").files[0];

    var formData = new FormData();
    if (this.state.enableEditing) {
      const { myCampaign } = this.props;
      myCampaign.start_date = new Date(myCampaign.start_date)
        .toISOString()
        .slice(0, 10);
      myCampaign.end_date = new Date(myCampaign.end_date)
        .toISOString()
        .slice(0, 10);
      for (let key in campaignData) {
        if (campaignData[key] !== myCampaign[key] && key !== "media")
          formData.append(key, campaignData[key]);
      }
      this.props.updateCampaign(formData);
    } else {
      campaignData.currency = campaignData.currency.value;
      for (let key in campaignData) {
        formData.append(key, campaignData[key]);
      }
      this.props.createCampaign(formData);
    }
  };

  resharerComponent = (url) => {
    let sharer = [
      "Facebook",
      "Twitter",
      "Telegram",
      "Whatsapp",
      "Linkedin",
      "Reddit",
      "Email",
    ];
    return (
      <div
        style={{
          paddingTop: "10px",
          display: "flex",
          justifyContent: "space-between ",
        }}
      >
        {sharer.map((s) => {
          let ShareButton = SharerPlugin[s + "ShareButton"];
          let Icon = SharerPlugin[s + "Icon"];
          return (
            <ShareButton url={url}>
              <Icon size={32} round />
            </ShareButton>
          );
        })}
      </div>
    );
  };

  render() {
    const { userAuth, myCampaign } = this.props;
    if (!(userAuth.user && userAuth.user.userInfo && userAuth.user.userInfo.id))
      return (
        <div className="account-container">
          <Paper className="account-form">
            <Typography variant={"subtitle2"}>
              Please complete your profile to proceed.
            </Typography>
          </Paper>
        </div>
      );
    else if (myCampaign && myCampaign.id && !this.state.enableEditing)
      return (
        <div className="account-container">
          <Dialog
            onClose={() => {
              this.setState({ copyLinkBox: false });
            }}
            open={this.state.copyLinkBox}
          >
            <DialogTitle>Copy this Link to share</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  variant={"subtitle2"}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    navigator.clipboard.writeText(
                      window.location.origin +
                        "/publiccampaign/" +
                        myCampaign.id
                    );
                    toast.info("Link Copied!");
                  }}
                >
                  {" "}
                  {window.location.origin + "/publiccampaign/" + myCampaign.id}
                  &nbsp;{" "}
                  <span style={{ position: "relative", top: "8px" }}>
                    <FileCopyIcon />
                  </span>
                </Typography>
                {this.resharerComponent(
                  window.location.origin + "/publiccampaign/" + myCampaign.id
                )}
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Paper className="account-form">
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="h4">Your Campaign</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.setState({ enableEditing: true });
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ textAlign: "right" }}
                onClick={() => {
                  if (userAuth.user.userInfo.jjkp === false) {
                    toast.error("KYC rejected please resubmit your documents!");
                  } else if (userAuth.user.userInfo.jjkp === null) {
                    toast.error(
                      "Your KYC is still pending, please wait till aprroved!"
                    );
                  }
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!userAuth.user.userInfo.jjkp}
                  onClick={() => {
                    this.setState({ copyLinkBox: true });
                  }}
                >
                  Share
                </Button>
              </Grid>
              <Grid item xs={12}>
                <CampaignComponent campaign={myCampaign} />
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    else if (myCampaign === null) return <div />;
    else
      return (
        <div className="account-container">
          <Paper className="account-form">
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="h4">
                  {myCampaign && myCampaign.id ? "Update" : "Create"} Campaign
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "right" }}>
                {myCampaign && myCampaign.id ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.setState({ enableEditing: false });
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} className="campaign-form">
                <div style={{ textAlign: "center" }}>
                  <Button component="label">
                    <Paper>
                      <img
                        id="campaign-cover"
                        src={
                          myCampaign && myCampaign.media
                            ? myCampaign.media
                            : campaign_media
                        }
                        width="250px"
                        alt={"Upload Cover"}
                      />
                      <input
                        id="campaign-media"
                        type="file"
                        hidden
                        onChange={this.onMediaUpload}
                      />
                    </Paper>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className="campaign-form">
                <CreateCampaignForm
                  initialValues={
                    myCampaign && myCampaign.id
                      ? {
                          ...myCampaign,
                          start_date: new Date(myCampaign.start_date)
                            .toISOString()
                            .slice(0, 10),
                          end_date: new Date(myCampaign.end_date)
                            .toISOString()
                            .slice(0, 10),
                        }
                      : {}
                  }
                  onSubmit={this.onFormSubmit}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.loginReducer.userAuth,
    myCampaign: state.createCampaignReducer.myCampaign,
  };
};

export default connect(mapStateToProps, {
  createCampaign,
  getMyCampaign,
  updateCampaign,
})(CreateCampaign);
