import {
  FETCH_PUBLIC_CAMPAIGN,
  INITIATE_TRANSACT_PUBLIC_CAMPAIGN,
  INITIATE_STRIPE,
  STRIPE_CLOSE,
  STRIPE_PK,
  STRIPE_STATUS,
  PAYPAL_APPROVED,
} from "../../constants";

import { loadStripe } from "@stripe/stripe-js";

let initialState = {
  campaign: null,
  transaction: null,
  stripeInitiate: false,
  stripePromise: null,
  stripeStatus: null,
  paypalApproved: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_CAMPAIGN:
      return { ...state, campaign: action.data };
    case INITIATE_TRANSACT_PUBLIC_CAMPAIGN:
      return { ...state, transaction: action.data };
    case INITIATE_STRIPE:
      const promise = loadStripe(STRIPE_PK);
      return { ...state, stripeInitiate: true, stripePromise: promise };
    case STRIPE_CLOSE:
      return { ...state, stripeInitiate: false, stripePromise: null };
    case STRIPE_STATUS:
      return { ...state, stripeStatus: action.data };
    case PAYPAL_APPROVED:
      return { ...state, paypalApproved: action.data };
    default:
      return state;
  }
};
