import {
  FETCH_PUBLIC_CAMPAIGN,
  INITIATE_TRANSACT_PUBLIC_CAMPAIGN,
  INITIATE_STRIPE,
  STRIPE_CLOSE,
  STRIPE_STATUS,
  PAYPAL_APPROVED,
} from "../../constants";

import axios from "axios";
import { toast } from "react-toastify";

export const getCampaignById = (id) => {
  return (dispatch) => {
    axios
      .get("/publiccampaign/campaign/" + id)
      .then((res) => {
        dispatch({ type: FETCH_PUBLIC_CAMPAIGN, data: res.data.data.campaign });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const initiateTransaction = (data) => {
  return (dispatch) => {
    axios
      .post("/transact/initiateinvest/", data)
      .then((res) => {
        dispatch({
          type: INITIATE_TRANSACT_PUBLIC_CAMPAIGN,
          data: res.data.data.transaction,
        });
        switch (data.payment_gateway) {
          case "coinbase":
            if (res.data.data.transaction.transaction_info.hosted_url) {
              window.location =
                res.data.data.transaction.transaction_info.hosted_url;
            } else {
              toast.error(
                "Error with Payment please refresh page and try again"
              );
            }
            break;
          case "stripe":
            if (res.data.data.transaction.transaction_info.client_secret) {
              dispatch(initiateStripePayment());
            } else {
              toast.error(
                "Error with Payment please refresh page and try again"
              );
            }
            break;
          case "paypal":
            if (
              res.data.data.transaction.transaction_info.purchase_units.length >
              0
            ) {
              dispatch({ type: PAYPAL_APPROVED, data: true });
              toast.info("Payment Success");
              dispatch({ type: PAYPAL_APPROVED, data: false });
            } else {
              toast.error(
                "Error with Payment please refresh page and try again"
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const initiateStripePayment = () => {
  return (dispatch) => {
    dispatch({ type: INITIATE_STRIPE });
  };
};

export const closeStripePayment = () => {
  return (dispatch) => {
    dispatch({ type: STRIPE_CLOSE });
  };
};

export const stripeStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: STRIPE_STATUS, data: status });
    if (status === "success") {
      toast.info("Payment Success");
    } else {
      toast.error("Payment Failed");
    }
  };
};
