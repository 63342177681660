import { OPEN_LOGIN, LOGIN_RESPONSE, SIGNUP_RESPONSE } from "../../constants";

let initialState = {
  loginInitiate: false,
  userAuth: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return { ...state, loginInitiate: action.data };
    case LOGIN_RESPONSE:
      return { ...state, userAuth: action.data, loginInitiate: false };
    case SIGNUP_RESPONSE:
      return { ...state, userAuth: action.data, loginInitiate: false };
    default:
      return state;
  }
};
