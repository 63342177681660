import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./container/combineReducer";
import thunk from "redux-thunk";
// import { throttle } from "lodash";

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("live", serializedState);
  } catch {
    // ignore write errors
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("live");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export default function configureStore() {
  let composeEnhancers = compose;
  const persistedState = loadState();
  if (process.env.NODE_ENV !== "production" && typeof window === "object") {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const enhancers = [applyMiddleware(thunk)];

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(...enhancers)
  );

  // store.subscribe(
  //   throttle(() => {
  //     saveState(store.getState());
  //   }, 5000)
  // );
  
  store.subscribe(() => {
    saveState({
      loginReducer: store.getState().loginReducer
    });
  });

  return store;
}
