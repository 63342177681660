import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

import CampaignCard from "../HomePage/components/CampaignCard";
import { getPublicCampaign } from "../HomePage/actions";

import InvestorForm from "../Account/FormComponents/Investor";
import {
  getMyInvestorAccount,
  uploadAvatar,
  updateName,
  createMyInvestorAccount,
  updateMyInvestorAccount,
} from "../Account/actions";

import cards_icon from "../../resources/images/cards.png";
import paypal_icon from "../../resources/images/paypal.png";
import crypto_icon from "../../resources/images/crypto.png";

import { getMyTransactions } from "./actions";
import "./style.css";

class Login extends Component {
  state = {
    tab: 0,
  };

  submitInvestor = (data) => {
    const {
      myAccount,
      createMyInvestorAccount,
      updateMyInvestorAccount,
    } = this.props;
    var formData = new FormData();
    const investorInfo = { ...data };
    if (myAccount.account && myAccount.account.id) {
      for (let keys in myAccount.account) {
        if (
          investorInfo[keys] !== myAccount.account[keys] &&
          investorInfo[keys] !== undefined &&
          keys !== "identification_card"
        ) {
          formData.append(keys, investorInfo[keys]);
        }
      }
      updateMyInvestorAccount(formData);
    } else {
      if (investorInfo.identification_card)
        formData.append(
          "identification_card",
          investorInfo.identification_card[0]
        );
      formData.append("address", investorInfo.address);
      formData.append("city", investorInfo.city);
      formData.append("state", investorInfo.state);
      formData.append("zip", investorInfo.zip);
      createMyInvestorAccount(formData);
    }
  };

  componentDidMount() {
    this.props.getMyTransactions();
    this.props.getPublicCampaign();
  }

  a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  handleTabChange = (event, newValue) => {
    const { getMyInvestorAccount } = this.props;
    if (newValue === 1) {
      getMyInvestorAccount();
    }

    this.setState({ tab: newValue });
  };

  contributionsCard = (props) => {
    const { myTransaction } = props;
    const paidByIcon = {
      stripe: cards_icon,
      coinbase: crypto_icon,
      paypal: paypal_icon,
    };
    return (
      <Paper variant="outlined" style={{ margin: "15px 0px", padding: "15px" }}>
        <b>Order Id:</b> {myTransaction.id}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Link to={"/publiccampaign/" + myTransaction.campaign_id}>
              <Typography
                variant={"h6"}
                style={{ textTransform: "capitalize" }}
              >
                {myTransaction.title}
              </Typography>
              <Typography variant={"body1"}>
                {myTransaction.description.length > 130
                  ? myTransaction.description.slice(0, 130).concat("...")
                  : myTransaction.description}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} style={{ fontSize: "small" }}>
              <Grid item xs={3}>
                <b>Paid:</b> {myTransaction.currency} {myTransaction.value}
              </Grid>
              <Grid item xs={5}>
                <b>Date:</b>{" "}
                {new Date(myTransaction.transaction_time).toLocaleString(
                  "en-US"
                )}
              </Grid>
              <Grid item xs={4}>
                <b>Status:</b> {myTransaction.status}
              </Grid>

              <Grid item xs={3}>
                <b>Paid by:</b>{" "}
                <img
                  src={paidByIcon[myTransaction.payment_gateway]}
                  height="18px"
                  alt="paidby"
                />
              </Grid>
              <Grid item xs={9}>
                <b>Transaction Id:</b> {myTransaction.transaction_id}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderDashboard = (props) => {
    const { myTransactions, pageFeed } = this.props;
    pageFeed.length = 3;
    const ContributionCard = this.contributionsCard;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h4"} style={{ textAlign: "center" }}>
            Projects Open for Crowd Sales
          </Typography>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={
              window.innerHeight > 700
                ? window.innerHeight / 5.38
                : window.innerHeight / 5.38 + window.innerHeight / 25
            }
            totalSlides={pageFeed.length}
            isPlaying={false}
            visibleSlides={3}
            interval={10000}
            hasMasterSpinner={pageFeed.length === 0}
          >
            <Slider style={{ padding: "30px" }}>
              {pageFeed.map((item, i) => {
                return (
                  <Slide key={i}>
                    <CampaignCard campaign={item} />
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
          <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
            <Link to="/campaigns/public">
              <Button variant="contained" color="primary">
                Browse Crowd Sale Projects
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "30px" }}>
            <Typography variant="h6">PORTFOLIO SUMMARY</Typography>
            <Paper
              variant="outlined"
              style={{ border: "solid 1px #45ad7e", padding: "30px" }}
            >
              <Typography variant={"h6"}>
                No. Contributions {myTransactions.length}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "21% 0% 10px",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="subtitle1">Total Invested</Typography>
                    <Typography variant="h6">0.00</Typography>
                    <hr style={{ width: "50%" }} />
                    <Typography variant="subtitle1">Total Invested</Typography>
                    <Typography variant="h6">0.00</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={8}>
                  <Paper
                    variant="outlined"
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="hr-sect">Returned</div>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Invested</Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Profits</Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">
                          Annualized Average Profitability
                        </Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="hr-sect">Pending</div>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Invested</Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Profits</Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">
                          Annualized Average Profitability
                        </Typography>
                        <Typography variant="h6">0.00</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
            <div style={{ width: "100%", textAlign: "right", padding: "30px" }}>
              <Link to="/campaigns/public">
                <Button variant="contained" color="primary">
                  {" "}
                  Invest Now{" "}
                </Button>
              </Link>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "5px 30px" }}>
            <Typography variant={"h6"}>My Transactions</Typography>
            {myTransactions.map((transaction) => (
              <ContributionCard myTransaction={transaction} />
            ))}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { a11yProps, TabPanel } = this;
    const { tab } = this.state;
    const RenderDashboard = this.renderDashboard;
    const { userAuth, myAccount, uploadAvatar, updateName } = this.props;
    return (
      <div style={{ padding: "30px" }}>
        <Paper style={{ padding: "20px" }}>
          <Typography variant={"h3"}>Dashboard</Typography>
          <Grid container>
            <Grid item xs={2}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tab}
                onChange={this.handleTabChange}
                aria-label="Vertical tabs example"
              >
                <Tab
                  classes={{
                    wrapper: "muitabwrapper",
                  }}
                  label="Dashboard"
                  {...a11yProps(0)}
                />
                <Tab
                  classes={{
                    wrapper: "muitabwrapper",
                  }}
                  label="Profile"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
            <Grid item xs={10}>
              <TabPanel value={tab} index={0}>
                <RenderDashboard />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <InvestorForm
                  initialValues={myAccount.account}
                  user={userAuth.user}
                  onSubmit={this.submitInvestor}
                  uploadAvatar={uploadAvatar}
                  updateName={updateName}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myTransactions: state.dashboardReducer.transactions,
    myAccount: state.accountReducer.myAccount,
    userAuth: state.loginReducer.userAuth,
    pageFeed: state.homeReducer.pageFeed,
  };
};

export default connect(mapStateToProps, {
  getMyTransactions,
  getMyInvestorAccount,
  uploadAvatar,
  updateName,
  createMyInvestorAccount,
  updateMyInvestorAccount,
  getPublicCampaign,
})(Login);
