// src/components/StudentInfo/FundingForm.js
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Grid, Typography, Paper, TextField, 
  MenuItem,
  Checkbox,
  FormControlLabel, Dialog } from "@material-ui/core";
import { fundingEnquiry, toggleFundingForm} from './actions';
import CloseButton from '../CloseButton';


// Validation function
const validate = (values) => {
  const errors = {};
  if (!values.fullName) errors.fullName = 'Required';
  else if(values.fullName.length < 3) errors.fullName = 'Minimum 3 chars are required';
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.course) errors.course = 'Required';
  if (!values.stage) errors.stage = 'Required';
  if (!values.fundingRequired || values.fundingRequired <= 0) {
    errors.fundingRequired = 'Must be greater than 0';
  }
//   if (!values.personalContribution || values.personalContribution < 0) {
//     errors.personalContribution = 'Cannot be negative';
//   }
  if (!values.employmentStatus) errors.employmentStatus = 'Required';
  return errors;
};

// Render TextField for Redux Form
const renderTextField = ({ input, label, type, meta: { touched, error }, ...custom }) => (
  <TextField
    {...input}
    label={label}
    type={type}
    fullWidth
    error={touched && !!error}
    helperText={touched && error}
    {...custom}
  />
);

// Render Select for Redux Form
const renderSelect = ({ input, label, children, meta: { touched, error } }) => (
  <TextField
    {...input}
    select
    label={label}
    fullWidth
    error={touched && !!error}
    helperText={touched && error}
  >
    {children}
  </TextField>
);

// Render Textarea (using TextField with multiline)
const renderTextarea = ({ input, label, meta: { touched, error } }) => (
  <TextField
    {...input}
    label={label}
    multiline
    rows={3}
    fullWidth
    error={touched && !!error}
    helperText={touched && error}
  />
);

// Render Checkbox for Redux Form
const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    control={<Checkbox {...input} checked={!!input.value} />}
    label={label}
  />
);

const EnquiryForm = ({ handleSubmit, pristine, submitting, fundingEnquiry, isFormOpen, toggleFundingForm }) => {
  const onSubmit = (values) => {
    const formData = {
      fullName: values.fullName,
      email: values.email,
      course: values.course,
      stage: values.stage,
      fundingRequired: values.fundingRequired,
      personalContribution: values.personalContribution || 0,
      employmentStatus: values.employmentStatus,
      employmentProspects: values.employmentProspects,
      ownsDigitalAssets: values.ownsDigitalAssets || false,
      comments: values.comments,
    };
    fundingEnquiry(formData);
  };

  return (
    <Dialog
            open={isFormOpen}
            onClose={toggleFundingForm}
            aria-labelledby="Alumna DeFi Funding Enquiry"
            fullWidth={true}
            maxWidth={"md"}
          >
        <div style={{ minWidth: '600px', margin: '20px auto', padding: '40px' }}>
      <Paper
        elevation={3}
        style={{
          backgroundColor: '#45ad7e', // Highlight color (from your prior example)
          padding: '10px 20px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h5"
          style={{ color: 'white' }} // White text for contrast
        >
          Alumna DeFi Funding Enquiry
        </Typography>
        </Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* Personal Info */}
          <Grid item xs={12}>
            <Field
              name="fullName"
              component={renderTextField}
              label="Full Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              component={renderTextField}
              label="Email"
              type="email"
              fullWidth
            />
          </Grid>

          {/* Educational Info */}
          <Grid item xs={12}>
            <Field
              name="course"
              component={renderTextField}
              label="Intended Course of Study (STEM preferred)"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field name="stage" component={renderSelect} label="Stage in Education">
              <MenuItem value="">Select...</MenuItem>
              <MenuItem value="pre-admission">Pre-admission</MenuItem>
              <MenuItem value="current-student">Current Student</MenuItem>
              <MenuItem value="near-graduation">Nearing Graduation</MenuItem>
            </Field>
          </Grid>

          {/* Funding Needs */}
          <Grid item xs={12}>
            <Field
              name="fundingRequired"
              component={renderTextField}
              label="Estimated Funding Required (USD)"
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="personalContribution"
              component={renderTextField}
              label="Personal Contribution (USD)"
              type="number"
              fullWidth
            />
          </Grid>

          {/* Employment Outlook */}
          <Grid item xs={12}>
            <Field name="employmentStatus" component={renderSelect} label="Current Employment Status">
              <MenuItem value="">Select...</MenuItem>
              <MenuItem value="unemployed">Unemployed</MenuItem>
              <MenuItem value="part-time">Part-time</MenuItem>
              <MenuItem value="full-time">Full-time</MenuItem>
              <MenuItem value="on-sabbatical">On Sabbatical</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="employmentProspects"
              component={renderTextarea}
              label="Post-Graduation Employment Prospects"
            />
          </Grid>

          {/* Digital Assets */}
          <Grid item xs={12}>
            <Field
              name="ownsDigitalAssets"
              component={renderCheckbox}
              label="I own digital assets (e.g., Bitcoin, Ether)"
            />
          </Grid>

          {/* Comments */}
          <Grid item xs={12}>
            <Field name="comments" component={renderTextarea} label="Additional Comments" />
          </Grid>

          {/* Buttons */}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={pristine || submitting}
            >
              Submit Enquiry
            </Button>
          </Grid>
          <Grid item xs={6}>
            {/* Optional Clear Button (uncomment if needed) */}
            {/* <Button
              variant="outlined"
              color="secondary"
              fullWidth
              disabled={pristine || submitting}
              onClick={() => props.reset()}
            >
              Clear
            </Button> */}
              <CloseButton onClick={toggleFundingForm} disabled={submitting} />
          </Grid>
        </Grid>
      </form>
    </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    isFormOpen: state.StudentInfoReducer.isFormOpen
  };
};

export default connect(mapStateToProps, { fundingEnquiry, toggleFundingForm })(
  reduxForm({
    form: 'enquiryForm', // Unique name for the form
    validate,
  })(EnquiryForm)
);
