import { GET_MY_CAMPAIGN } from "../../constants";

let initialState = {
  myCampaign: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_CAMPAIGN:
      return { ...state, myCampaign: action.data.data.campaign };
    default:
      return state;
  }
};
