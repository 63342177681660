import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { loadProgressBar } from "axios-progress-bar";
import { ThemeProvider } from "@material-ui/core/styles";

import * as serviceWorker from "./serviceWorker";
import store from "./configureStore";

import axiosInstance from "./axiosDefaults";
import theme from "./utils/theme";
import App from "./container/App";

import "./index.css";
import "axios-progress-bar/dist/nprogress.css";

// require("module-alias/register");

axiosInstance();
loadProgressBar();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
