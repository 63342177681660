import { MY_ACCOUNT } from "../../constants";

import axios from "axios";
import { toast } from "react-toastify";
import {updateMe} from "../../components/Login/actions";

export const getMyAccount = () => {
  return (dispatch) => {
    axios
      .get("/student/myaccount")
      .then((res) => {
        dispatch({ type: MY_ACCOUNT, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getMyInvestorAccount = () => {
  return (dispatch) => {
    axios
      .get("/investor/myaccount")
      .then((res) => {
        dispatch({ type: MY_ACCOUNT, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createMyAccount = (student) => {
  return (dispatch) => {
    axios
      .post("/student/createStudent", student, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(getMyAccount());
        dispatch(updateMe());
        toast.info("Account creation has been successfully completed");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const createMyInvestorAccount = (investor) => {
  return (dispatch) => {
    axios
      .post("/investor/createInvestor", investor, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(getMyInvestorAccount());
        dispatch(updateMe());
        toast.info("Account creation has been successfully completed");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const updateMyAccount = (student) => {
  return (dispatch) => {
    axios
      .patch("/student/updateStudent", student, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(getMyAccount());
        dispatch(updateMe());
        toast.info("Account has been successfully updated");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const updateMyInvestorAccount = (student) => {
  return (dispatch) => {
    axios
      .patch("/investor/updateInvestor", student, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(getMyAccount());
        dispatch(updateMe());
        toast.info("Account has been successfully updated");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const updateMyResume = (resumeForm) => {
  return (dispatch) => {
    axios
      .post("/student/uploadResume", resumeForm)
      .then((res) => {
        dispatch({ type: MY_ACCOUNT, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const uploadMyId = (IdForm) => {
  return (dispatch) => {
    axios
      .post("/student/uploadIdentification", IdForm)
      .then((res) => {
        dispatch({ type: MY_ACCOUNT, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const uploadAvatar = (file) => {
  return (dispatch) => {
    var formData = new FormData();
    formData.append("profile_media", file);
    axios
      .post("/users/update-avatar", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(updateMe());
        toast.info("Profile photo uploaded");
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateName = (user) => {
  return (dispatch) => {
    axios
      .post("/users/update-name", user)
      .then((res) => {
        // dispatch({ type: MY_ACCOUNT, data: res.data });
        dispatch(updateMe());
        toast.info("Name updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
