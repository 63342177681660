import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";

import {
  AppBar,
  Toolbar,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  Grid,
  Slide,
  useScrollTrigger,
} from "@material-ui/core";

import {
  AccountCircle as AccountCircleIcon,
  MoreVert as MoreVertIcon,
  Settings as SettingsIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Dashboard as DashboardIcon,
  Publish as PublishIcon,
} from "@material-ui/icons";

import { Link, NavLink, useHistory } from "react-router-dom";

import { logout, initiateLogin } from "../../../components/Login/actions";

// import Logo from "../../../resources/images/AlumnaLogo.png";
// const Logo = "https://alumna-pub.s3.af-south-1.amazonaws.com/logo_new.png";
const Logo = "https://alumna-pub.s3.af-south-1.amazonaws.com/media/alumna_text_logo.png";

function RenderProfilePart({ user }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      style={{
        textAlign: "center",
      }}
    >
      {/* <Grid item xs={2}>
        <AccountCircleIcon style={{ marginTop: "10px" }} />
      </Grid> */}
      <Grid item xs={12}>
        <Button
          disableElevation
          color="primary"
          aria-label="more"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ height: "82px" }}
        >
          <AccountCircleIcon /> &nbsp;
          {user.firstname} &nbsp; <MoreVertIcon />
        </Button>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            // top: "50px",
            // left: "890px",
            minWidth: "177px",
          },
        }}
      >
        {user.user_type === "student" ? (
          <NavLink to="/campaign" style={{ textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>
              <PublishIcon /> &nbsp; Campaign
            </MenuItem>
          </NavLink>
        ) : (
          <>
            <NavLink to="/dashboard" style={{ textDecoration: "none" }}>
              <MenuItem>
                <DashboardIcon /> &nbsp; Dashboard
              </MenuItem>
            </NavLink>
            <NavLink to="/techfaqs" style={{ textDecoration: "none" }}>
              <MenuItem>
                <MoreVertIcon /> &nbsp; Investor Guide
              </MenuItem>
            </NavLink>
          </>
        )}

        {user.user_type === "student" && (
          <NavLink to="/profile" style={{ textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>
              <SettingsIcon /> &nbsp; Profile
            </MenuItem>
          </NavLink>
        )}
        <MenuItem
          onClick={() => {
            dispatch(logout());
            handleClose();
          }}
        >
          <PowerSettingsNewIcon /> &nbsp;Logout
        </MenuItem>
      </Menu>
    </Grid>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuth = useSelector((state) => state.loginReducer.userAuth);
  const user = userAuth.user || {};
  return (
    <div>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position="fixed" color="neutral">
          <Toolbar>
            <Grid
              container
              style={{
                height: "85%",
                alignItems: "center",
              }}
            >
              <Grid item xs={2}>
                {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
                <Link to="/">
                  <img
                    src={Logo}
                    style={{
                      height: "68px",
                      marginTop: "9px",
                    }}
                    alt="alumna-logo"
                  />
                </Link>
              </Grid>
              <Grid item xs={8} style={{ textAlign: "right" }}>
                {props.internalLinks &&
                  props.internalLinks.map((item) =>
                    item.onClick ? (
                      <Button
                        disableElevation
                        color="primary"
                        onClick={() => {
                          if (history.location.pathname === item.onPage) {
                            item.onClick();
                          } else {
                            history.push(item.onPage);
                            setTimeout(() => {
                              item.onClick();
                            }, 50);
                          }
                        }}
                        style={{ height: "82px" }}
                      >
                        {item.title}
                      </Button>
                    ) : (
                      <Link to={item.link}>
                        <Button
                          disableElevation
                          color="primary"
                          style={{ height: "82px" }}
                        >
                          {item.title}
                        </Button>
                      </Link>
                    )
                  )}
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                {user.id ? (
                  <RenderProfilePart user={user} />
                ) : (
                  <Button
                    disableElevation
                    color="primary"
                    onClick={() => {
                      dispatch(initiateLogin());
                    }}
                    style={{ height: "82px" }}
                  >
                    Login / Signup
                  </Button>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
}

export default Navbar;
