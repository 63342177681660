import { GET_MY_TRANSACTIONS } from "../../constants";

import axios from "axios";
import { toast } from "react-toastify";

export const getMyTransactions = () => {
  return (dispatch) => {
    axios
      .get("/transact/mytransactions")
      .then((res) => {
        dispatch({
          type: GET_MY_TRANSACTIONS,
          data: res.data.data.transactions,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};
