import React from "react";
import { reduxForm } from "redux-form";
import { Button, Grid } from "@material-ui/core";
// import {
//   renderTextField,
//   renderSelectField,
// } from "../../../utils/reduxFormComponents";

const InstituteAdminForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={pristine || submitting}
            onClick={reset}
            fullWidth
          >
            Clear Values
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || submitting}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default reduxForm({
  form: "InstituteAdminForm", // a unique identifier for this form
})(InstituteAdminForm);
