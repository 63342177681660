import React from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import { toast } from "react-toastify";

import * as SharerPlugin from "react-share";

const resharerComponent = (url) => {
  let sharer = [
    "Facebook",
    "Twitter",
    "Telegram",
    "Whatsapp",
    "Linkedin",
    "Reddit",
    "Email",
  ];
  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        justifyContent: "space-between ",
      }}
    >
      {sharer.map((s) => {
        let ShareButton = SharerPlugin[s + "ShareButton"];
        let Icon = SharerPlugin[s + "Icon"];
        return (
          <ShareButton url={url}>
            <Icon size={32} round />
          </ShareButton>
        );
      })}
    </div>
  );
};

function SharerComponent(props) {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Copy this Link to share</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant={"subtitle2"}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              navigator.clipboard.writeText(
                window.location.origin + props.link
              );
              toast.info("Link Copied!");
            }}
          >
            {" "}
            {window.location.origin + props.link}
            &nbsp;{" "}
            <span style={{ position: "relative", top: "8px" }}>
              <FileCopyIcon />
            </span>
          </Typography>
          {resharerComponent(window.location.origin + props.link)}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default SharerComponent;
