import { GET_MY_TRANSACTIONS } from "../../constants";

let initialState = {
  transactions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_TRANSACTIONS:
      return { ...state, transactions: action.data };
    default:
      return state;
  }
};
