import React from "react";

function InternalServerError() {
  return (
    <div
      style={{
        margin: "auto",
        lineHeight: "1.4",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "236px",
          fontWeight: "200",
          margin: "0px",
          color: "#211b19",
          textTransform: "uppercase"
        }}
      >
        Oops!
      </h1>
      <h2
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "28px",
          fontWeight: 400,
          textTransform: "uppercase",
          color: "#211b19",
          background: "#fff",
          padding: "10px 5px",
          margin: "auto",
        }}
      >
        Something went wrong. We're looking to see what happened.
      </h2>
    </div>
  );
}

export default InternalServerError;
