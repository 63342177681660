import { combineReducers } from "redux";
import { reducer as forms } from "redux-form";

import loginReducer from "../components/Login/reducer";

import homeReducer from "./HomePage/reducer";
import profileReducer from "./Profile/reducer";
import accountReducer from "./Account/reducer";
import createCampaignReducer from "./CreateCampaign/reducer";
import publicCampaign from "./PublicCampaign/reducer";
import dashboardReducer from "./Dashboard/reducer";
import StudentInfoReducer from "../components/StudentInfo/reducer";

export default combineReducers({
  form: forms,
  homeReducer,
  loginReducer,
  profileReducer,
  accountReducer,
  createCampaignReducer,
  publicCampaign,
  dashboardReducer,
  StudentInfoReducer
});
