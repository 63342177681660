import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Define custom styles
const useStyles = makeStyles((theme) => ({
    closeButton: {
        backgroundColor: 'white', // Set background color to red
        color: 'red', // Set text color to white
        '&:hover': {
            backgroundColor: 'darkred', // Darker red on hover
        },
    },
}));

function CloseButton(props) {
    const classes = useStyles();

    return (
        <Button
        {...props} // Pass any additional props to the Button component
            variant="outlined   " // Use contained style for a solid button
            className={classes.closeButton} // Apply custom styles
            fullWidth // Make the button take up the full width of the container
        >
            Close
        </Button>
    );
}

export default CloseButton;