export const FETCH_HOME = "FETCH_HOME";
export const TESTIMONIALS = "TESTIMONIALS";
export const FETCH_PUBLIC_CAMPAIGN = "FETCH_PUBLIC_CAMPAIGN";
export const FETCH_STUDENT_CAMPAIGN = "FETCH_STUDENT_CAMPAIGN";
export const INITIATE_TRANSACT_PUBLIC_CAMPAIGN =
  "INITIATE_TRANSACT_PUBLIC_CAMPAIGN";

/* USERS */
export const OPEN_LOGIN = "OPEN_LOGIN";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const SIGNUP_RESPONSE = "SIGNUP_RESPONSE";
export const MY_ACCOUNT = "MY_ACCOUNT";

export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const GET_STUDENT_PROFILE = "GET_STUDENT_PROFILE";

export const FUNDING_ENQUIRY_SUCCESS = 'FUNDING_ENQUIRY_SUCCESS';
export const FUNDING_ENQUIRY_FAIL = 'FUNDING_ENQUIRY_FAIL';
export const TOGGLE_FUNDING_FROM = 'TOGGLE_FUNDING_FROM';

/* Campaigns */
export const GET_MY_CAMPAIGN = "GET_MY_CAMPAIGN";

/* Dashboard */
export const GET_MY_TRANSACTIONS = "GET_MY_TRANSACTIONS";

// export const GET_PUBLIC_TRANSACTIONS = "GET_PUBLIC_TRANSACTIONS";

export const STRIPE_PK =
  "pk_test_51HxK2QAj0DXcoZlgAQCMc9cDBUPNVQfqhdLaNnOx6b8ChC10NRBest383Y7NhHS20g7neZbh0pghWgJUNllR1oLa00fljl1YoF";
export const PAYPAL_KEY =
  "AYw5z2LS5gLGUi89sxT1f68cR5mmHRd4OzY1RZg9CG4VrurWSZEr0sYyhz1cUqTRhIAxzdDrgO8gQMMC";

export const INITIATE_STRIPE = "INITIATE_STRIPE";
export const STRIPE_CLOSE = "STRIPE_CLOSE";
export const STRIPE_STATUS = "STRIPE_STATUS";

export const PAYPAL_APPROVED = "PAYPAL_APPROVED";
