import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid } from "@material-ui/core";
import { renderTextField } from "../../../utils/reduxFormComponents";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["email", "otp", "password"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  if (values.password && values.confirmPassword !== values.password) {
    errors.confirmPassword = "Password doesn't match";
  }
  return errors;
};

const ForgotPasswordForm = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Field
            id="email-field"
            name="username"
            component={renderTextField}
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.getOTP(document.getElementById("email-field").value);
            }}
          >
            Get OTP
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Field name="otp" component={renderTextField} label="OTP" fullWidth />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Field
            name="confirmPassword"
            component={renderTextField}
            label="Confirm Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || submitting}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "ForgotPasswordForm",
  validate,
})(ForgotPasswordForm);
