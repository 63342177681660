import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import image1 from "../../resources/lpc/1.jpg";

export default function (props) {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "92vh",
          textAlign: "center",
          color: "white",
          textShadow: "1px 2px 3px #000",
          paddingTop: "15%",
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{ margin: "10px", textShadow: "none" }}
          color="primary"
          href="/cdn/CallToGlobal.pdf"
        >
          <span style={{ color: "white" }}>Call to Global Action</span>
        </Button>
        <br />
        <Button
          variant="contained"
          size="large"
          style={{ margin: "10px", textShadow: "none" }}
          color="primary"
          href="/cdn/Alumna-White-Paper-6.pdf"
        >
          <span style={{ color: "white" }}> Alumna White Paper</span>
        </Button>
        <br />
        <Link to="/techfaqs">
          <Button
            variant="contained"
            size="large"
            style={{ margin: "10px", textShadow: "none" }}
            color="secondary"
          >
            <span style={{ color: "white" }}>Investor Guide</span>
          </Button>
        </Link>
        <br />
        <Button
          variant="contained"
          size="large"
          style={{ margin: "10px", textShadow: "none" }}
          color="secondary"
          href="/cdn/FAQs.pdf"
        >
          <span style={{ color: "white" }}> FAQ's</span>
        </Button>
        {/* <object src="https://app.alumna.co.za/cdn/CallToGlobal.pdf"> */}
        {/* <embed
          src="https://app.alumna.co.za/cdn/CallToGlobal.pdf"
          view="FitH"
          width="70%"
          height="1125px"
        ></embed> */}
        {/* </object> */}

        {/* <Typography
          variant="h4"
          style={{ textAlign: "center" }}
          color="secondary"
        >
          A CALL TO (GLOBAL) ACTION!
        </Typography>
        <br />
        <Typography variant="body1">
          In an era of digitalization, Alumna offers an open platform with the
          sole objective of accelerating the evolution of our global education
          ecosystems. This will be achieved by leveraging all the advantages of
          technology that will offer a valuable and unifying ecosystem that
          reflects the positive (economic and social) externalities from the
          mass adoption of the Alumna platform. It will also allow the active
          engagement of more and more segments and stakeholders to the global
          education value chain.
        </Typography>
        <br />
        <Typography variant="body1">
          Alumna is offering a new disruptive model for the global education
          transition, incorporated within a financial ecosystem. The Alumna
          Network is governed by a set of mechanisms that promote and reward the
          expansion of sustainable and decentralized management and uptake of
          higher education.
        </Typography>
        <br />
        <Typography variant="body1">
          The Alumna Network will be the link between global higher education
          services and the global digital economy; an open platform based on the
          first sustainable and decentralized higher education based ecosystem,
          designed to propel the decentralization of global education markets
          into the future.
        </Typography>
        <br />
        <Typography variant="body1">
          <b>In a time</b> that the future of our education ecosystem, its
          resources and diversity are facing an unparalleled debt crisis and
          threat from the externalities of our economic and social activities...
        </Typography>
        <br />
        <Typography variant="body1">
          <b>In a time</b> where it is public knowledge of unprecedented warning
          regarding the sustainability of the future of our global economy
          caused by a lack of financial and socio-economic solutions.
        </Typography>
        <br />
        <Typography variant="body1">
          <b>In a time</b> that technology is out there - feasible and 100%
          proven - but our governance systems pose barriers and delays in its
          implementation...
        </Typography>
        <br />

        <Typography variant="h6" color="primary">
          At this Time:
        </Typography>

        <Typography variant="body1">
          Our ultimate goal should be cooperation and trust; cooperation as the
          only way to express our common desire for change and transformation;
          change for creating a common and fairer global education system and
          future for everyone. Alumna capitalizes trust; We recognize the
          synergy of the community and the value of the multiple bonds between
          its members towards closeness, communication, support and commitment
          towards prosperity.
        </Typography>
        <br />
        <Typography variant="h6" color="primary">
          This is the Moment:
        </Typography>

        <Typography variant="body1">
          We invite all stakeholders to join Alumna, an ecosystem of
          cooperation, based on transparency, for the benefit of our global
          education systems and the future well-being of our global society.
        </Typography>
        <br /> */}
      </div>
    </div>
  );
}
