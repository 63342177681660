import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid, Typography, Paper, TextField } from "@material-ui/core";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";

import axios from "axios";
import SharerComponent from "../../CommonComponents/SharerComponent";
import {
  renderSwitchField,
  uploadFileField,
  autoCompleteSelect,
} from "../../../utils/reduxFormComponents";

import {
  Timer as TimerIcon,
  Clear as ClearIcon,
  DoneAll as DoneAllIcon,
} from "@material-ui/icons";

import campaign_media from "../../../resources/images/userAvatar.png";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StudentForm = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    initialValues,
    user,
    onStudentBioChange,
    updateName,
    studentBio,
  } = props;
  const defaultEditorState =
    initialValues && initialValues.profile
      ? EditorState.createWithContent(convertFromRaw(initialValues.profile))
      : EditorState.createEmpty();
  const [editorState, setEditorState] = useState(defaultEditorState);
  // const [editorInitiated, setEditorInitiated] = useState(false);
  const [sharerOpen, setSharerOpen] = useState(false);
  const [editorStateSet, editorStateInitialized] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.profile && !editorStateSet) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(initialValues.profile))
      );
      editorStateInitialized(true);
    }
  }, [initialValues, editorStateSet]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onEditorContentChange = (profileContent) => {
    onStudentBioChange(profileContent);
  };

  const onMediaUpload = (e) => {
    let imgElement = document.getElementById("profile-pic");
    var reader = new FileReader();

    reader.onload = function (event) {
      imgElement.src = event.target.result;
    };

    reader.readAsDataURL(e.target.files[0]);
    props.uploadAvatar(e.target.files[0]);
  };

  // let editorParentRef = document.getElementsByClassName(
  //   "public-DraftEditor-content"
  // );
  // let editorRef = null;
  // if (editorParentRef.length > 0) {
  //   editorRef = editorParentRef[0].childNodes[0];
  //   console.log("editorref", editorRef);
  //   if (editorRef === "" && studentBio) {
  //     console.log("-----------", studentBio);
  //     editorRef.value = "Hello one";
  //   }
  // }

  return (
    <div style={{ margin: "auto", maxWidth: "900px" }}>
      <SharerComponent
        open={sharerOpen}
        onClose={() => {
          setSharerOpen(false);
        }}
        link={"/studentprofile/" + (initialValues ? initialValues.id : "")}
      />
      <Paper className="form-field-row" variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ padding: "20px" }}>
            <Typography variant="h6">Profile Photo</Typography>
            <Paper
              variant="outlined"
              style={{
                maxWidth: "70px",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px",
                marginTop: "30%",
                border: `${
                  initialValues && initialValues.kyc_reqii === null
                    ? "yellow"
                    : ""
                }
                ${
                  initialValues && initialValues.kyc_reqii === true
                    ? "lawngreen"
                    : ""
                }
                ${
                  initialValues && initialValues.kyc_reqii === false
                    ? "red"
                    : ""
                } solid`,
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  width: "fit-content",
                  padding: "5px 3px 0px 4px",
                  boxShadow: "1px 1px 4px grey",
                  margin: "auto",
                }}
              >
                {!initialValues ? <TimerIcon /> : ""}
                {initialValues && initialValues.kyc_reqii === null ? (
                  <TimerIcon />
                ) : (
                  ""
                )}
                {initialValues && initialValues.kyc_reqii === true ? (
                  <DoneAllIcon />
                ) : (
                  ""
                )}
                {initialValues && initialValues.kyc_reqii === false ? (
                  <ClearIcon />
                ) : (
                  ""
                )}
              </div>
              <br />
              KYC
              <br />
              {!initialValues ? <br /> : ""}
              {initialValues && initialValues.kyc_reqii === null
                ? "Waiting"
                : ""}
              {initialValues && initialValues.kyc_reqii === true ? "Done" : ""}
              {initialValues && initialValues.kyc_reqii === false
                ? "Rejected"
                : ""}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "left" }}>
              <Button component="label">
                <Paper>
                  <img
                    id="profile-pic"
                    src={
                      user.avatar_media_url
                        ? user.avatar_media_url
                        : campaign_media
                    }
                    width="250px"
                    alt={"Upload Cover"}
                  />
                  <input
                    id="profile-media"
                    type="file"
                    hidden
                    onChange={onMediaUpload}
                  />
                </Paper>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper className="form-field-row" variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ padding: "20px" }}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="First Name"
              fullWidth
              defaultValue={user.firstname}
              onBlur={(e) => {
                updateName({ firstname: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Last Name"
              fullWidth
              defaultValue={user.lastname}
              onBlur={(e) => {
                updateName({ lastname: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className="form-field-row" variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={3} style={{ padding: "20px" }}>
            <Typography variant="h6">About Me</Typography>
          </Grid>
          <Grid item xs={9}>
            <Editor
              editorState={editorState}
              // contentState={studentBio}
              wrapperClassName="editor-wrapper"
              editorClassName="main-editor"
              onEditorStateChange={onEditorStateChange}
              onContentStateChange={onEditorContentChange}
            />
          </Grid>
        </Grid>
      </Paper>
      <form onSubmit={handleSubmit}>
        <Paper className="form-field-row" variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">Learning Institute</Typography>
            </Grid>
            <Grid item xs={7} style={{ paddingTop: "15px" }}>
              <Field
                name="institute"
                component={
                  !(initialValues && initialValues.institute_id)
                    ? autoCompleteSelect
                    : (props) => (
                        <Typography
                          variant="button"
                          style={{ paddingLeft: "7.5px" }}
                        >
                          {props.input.value}
                        </Typography>
                      )
                }
                label="Institute"
                loadOptions={(input, callback) => {
                  if (input === "" || input === null) {
                    callback([]);
                  } else {
                    axios
                      .get("common/searchinstitute?key=" + input)
                      .then((res) => {
                        callback(res.data.data.institutes || []);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }}
                isDisabled={initialValues && initialValues.institute_id}
              />
            </Grid>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">Proof of Registration</Typography>
              <Typography variant="caption">(Learning Insitute)</Typography>
            </Grid>
            <Grid item xs={7} style={{ paddingTop: "15px" }}>
              <Field
                name="proof_of_reg"
                component={uploadFileField}
                label="Proof of Registration"
                type="file"
              />
            </Grid>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">ID/Passport</Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                name="identification_card"
                component={uploadFileField}
                label="ID Card"
                type="file"
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className="form-field-row" variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">Resume/CV</Typography>
            </Grid>
            <Grid item xs={7} style={{ paddingTop: "15px" }}>
              <Field
                name="resume"
                component={uploadFileField}
                label="Resume"
                type="file"
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className="form-field-row" variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">
                Profile Available for Funding
              </Typography>
              <Typography variant="subtitle1"> on Alumna Platform</Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                name="available_for_funding"
                component={renderSwitchField}
                label="Available for Funding"
              />
            </Grid>
            <Grid item xs={5} className="input-field">
              <Typography variant="h6">Profile Visibility</Typography>
              <Typography variant="subtitle1"> on Alumna Platform</Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                name="enable_search"
                component={renderSwitchField}
                label="Profile Enable"
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={3}>
          <Grid item xs={6} style={{ textAlign: "left", paddingLeft: "22px" }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!(initialValues && initialValues.id)}
              onClick={() => {
                setSharerOpen(true);
              }}
            >
              Share
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ textAlign: "right", paddingRight: "20px" }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={(pristine || submitting) && !studentBio}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default reduxForm({
  form: "StudentForm", // a unique identifier for this form
})(StudentForm);
