import React, { useEffect } from "react";
import { toast } from "react-toastify";

function EmailActivated() {
  useEffect(() => {
    toast.dark("After logging in, please complete your profile");
  });
  return (
    <div
      style={{
        margin: "auto",
        lineHeight: "1.4",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "60px",
          fontWeight: "200",
          margin: "0px",
          color: "#211b19",
          textTransform: "uppercase",
          marginTop: "50px",
        }}
      >
        Thanks for Signing up!
      </h1>
      <h2
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "28px",
          fontWeight: 400,
          textTransform: "uppercase",
          color: "#211b19",
          background: "#fff",
          padding: "10px 5px",
          margin: "auto",
        }}
      >
        Your Email is verified, please login to continue.
      </h2>
    </div>
  );
}

export default EmailActivated;
