import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid, Typography, Paper, TextField } from "@material-ui/core";
import {
  renderTextField,
  uploadFileField,
} from "../../../utils/reduxFormComponents";

import campaign_media from "../../../resources/images/userAvatar.png";

const InvestorForm = (props) => {
  const { handleSubmit, pristine, reset, submitting, user, updateName } = props;

  const onMediaUpload = (e) => {
    let imgElement = document.getElementById("profile-pic");
    var reader = new FileReader();

    reader.onload = function (event) {
      imgElement.src = event.target.result;
    };

    reader.readAsDataURL(e.target.files[0]);
    props.uploadAvatar(e.target.files[0]);
  };

  return (
    <div style={{ margin: "auto", maxWidth: "900px" }}>
      <Paper className="form-field-row" variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ padding: "20px" }}>
            <Typography variant="h6">Profile Photo</Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "left" }}>
              <Button component="label">
                <Paper>
                  <img
                    id="profile-pic"
                    src={
                      user && user.avatar_media_url
                        ? user.avatar_media_url
                        : campaign_media
                    }
                    width="250px"
                    alt={"Upload Cover"}
                  />
                  <input
                    id="profile-media"
                    type="file"
                    hidden
                    onChange={onMediaUpload}
                  />
                </Paper>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper className="form-field-row" variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ padding: "20px" }}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="First Name"
              fullWidth
              defaultValue={user && user.firstname}
              onBlur={(e) => {
                updateName({ firstname: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Last Name"
              fullWidth
              defaultValue={user && user.lastname}
              onBlur={(e) => {
                updateName({ lastname: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <form onSubmit={handleSubmit}>
        <Paper className="form-field-row" variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={4} className="input-field">
              <Typography variant="subtitle2">ID Card</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="identification_card"
                component={uploadFileField}
                label="ID Card"
                type="file"
              />
            </Grid>

            <Grid item xs={4} className="input-field">
              <Typography variant="subtitle2">Address Line 1</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="address"
                component={renderTextField}
                label="Address Line 1"
                fullWidth
              />
            </Grid>

            <Grid item xs={4} className="input-field">
              <Typography variant="subtitle2">City</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="city"
                component={renderTextField}
                label="City"
                fullWidth
              />
            </Grid>

            <Grid item xs={4} className="input-field">
              <Typography variant="subtitle2">State</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="state"
                component={renderTextField}
                label="State"
                fullWidth
              />
            </Grid>

            <Grid item xs={4} className="input-field">
              <Typography variant="subtitle2">Zip/Postal Code</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="zip"
                component={renderTextField}
                label="Zip"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid container>
          <Grid item xs={6} style={{ paddingLeft: "10px" }}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ paddingRight: "10px", textAlign: "right" }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default reduxForm({
  form: "InvestorForm", // a unique identifier for this form
})(InvestorForm);
