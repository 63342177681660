import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: "#45ad7e",
      main: "#38815b",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "#fff",
    },
    secondary: {
      // light: "#0066ff",
      // main: "#d6ca95",
      main: "#c7af44",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    neutral: {
      main: "#fff",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
theme = responsiveFontSizes(theme);

export default theme;
