import React, { useState } from "react";
import "./style.css";

import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Message as MessageIcon,
  GetApp as GetAppIcon,
} from "@material-ui/icons/";

import { Link } from "react-router-dom";

import twitterIcon from "../../../resources/images/twittericon.png";
import instaIcon from "../../../resources/images/instaicon.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "30px",
    backgroundColor: "#45ad7e",
  },
}));

function Footer() {
  const classes = useStyles();
  const [termsDialog, setTermsDialog] = useState(null);
  return (
    <div className={classes.footer}>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={termsDialog}
        onClose={() => {
          setTermsDialog(null);
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{termsDialog}</DialogTitle>
        <DialogContent>
          <embed
            src={"/cdn/" + termsDialog + ".pdf"}
            view="FitH"
            width="100%"
            height="600px"
          ></embed>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2}>
        <Grid
          item
          xs={5}
          style={{
            textAlign: "left",
            paddingLeft: "100px",
            borderRight: "solid 0.5px black",
            color: "white",
          }}
        >
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setTermsDialog("Legal Disclaimer Alumna");
            }}
          >
            Legal Disclaimer Alumna
          </Button>
          <br />
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setTermsDialog("Privacy Policy Alumna");
            }}
          >
            Privacy Policy Alumna
          </Button>
          <br />
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setTermsDialog("Terms and Conditions Alumna");
            }}
          >
            Terms and Conditions Alumna
          </Button>
          <br />
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setTermsDialog("Vulnerability Disclosure Policy Alumna");
            }}
          >
            Vulnerability Disclosure Policy Alumna
          </Button>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <Typography variant={"button"} component="span">
              Contact Us{" "}
            </Typography>
            <span style={{ paddingLeft: "10px" }}>
              <a href="mailto:info@alumna.co.za">
                <EmailIcon />
              </a>{" "}
              <a href="tel:+27767307080">
                <PhoneIcon />
              </a>{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/27767307080"
              >
                <MessageIcon />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/AlumnaPlatform"
              >
                <img src={twitterIcon} width="30px" alt="twitterIcon" />
              </a>{" "}
              &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/alumnaplatform/"
              >
                <img src={instaIcon} width="28px" alt="instaIcon" />
              </a>
            </span>
            <br />
          </div>
          <Typography style={{ padding: "5px 8px" }} variant={"subtitle1"}>
            © 2025 ALUMNA
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            textAlign: "center",
            color: "white",
            textShadow: "2px 2px #5b5b5b",
          }}
        >
          <Typography variant="h5">ALUMNA WHITE PAPER & FAQ'S.</Typography>
          <Typography variant="h6">Understand Our Vision</Typography>
          <div style={{ padding: "5px" }} />
          <Button
            variant="contained"
            size="large"
            style={{ margin: "7px", textShadow: "none" }}
            color="secondary"
            href="/cdn/Alumna-White-Paper-6.pdf"
          >
            <GetAppIcon /> Download Alumna White Paper
          </Button>
          <br />
          <Button
            variant="contained"
            size="large"
            style={{ margin: "7px", textShadow: "none" }}
            color="secondary"
            href="/cdn/FAQs.pdf"
          >
            <GetAppIcon /> Download FAQ's
          </Button>
          <br />
          <Link to="/blog">
            <Button
              variant="contained"
              size="large"
              style={{ margin: "7px", textShadow: "none" }}
              color="secondary"
            >
              <GetAppIcon /> Blogs
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
