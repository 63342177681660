import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, Tabs, Tab, Dialog } from "@material-ui/core";

import {
  tryLogin,
  doSignup,
  closeLogin,
  getOTP,
  submitPasswordWithOTP,
} from "./actions";

import LoginForm from "./Forms/loginForm";
import SignupForm from "./Forms/signupForm";
import ForgotPasswordForm from "./Forms/forgotPassword";

import "./style.css";

class LoginComponent extends Component {
  state = {
    loginTab: 0,
  };

  changeLoginTab = (e, val) => {
    this.setState({ loginTab: val });
  };

  loginSubmit = (data) => {
    this.props.tryLogin(data.username, data.password);
  };

  signupSubmit = (data) => {
    this.props.doSignup(data);
  };

  getOTP = (email) => {
    this.props.getOTP(email);
  };

  forgotPasswordSubmit = (data) => {
    this.props.submitPasswordWithOTP({
      email: data.email,
      otp: data.otp,
      password: data.password,
    });
  };

  renderForm = (tab) => {
    switch (tab) {
      case 0:
        return (
          <LoginForm
            onSubmit={this.loginSubmit}
            onForgotPasswordClick={() => {
              this.setState({ loginTab: 2 });
            }}
          />
        );
      case 1:
        return <SignupForm onSubmit={this.signupSubmit} />;
      case 2:
        return (
          <ForgotPasswordForm
            onSubmit={this.forgotPasswordSubmit}
            getOTP={this.getOTP}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { loginInitiate, closeLogin } = this.props;
    const { loginTab } = this.state;
    return (
      <Dialog
        open={loginInitiate}
        onClose={closeLogin}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Paper square>
          <Tabs
            value={loginTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={this.changeLoginTab}
            centered
          >
            <Tab label="Login" />
            <Tab label="Signup" />
          </Tabs>
        </Paper>
        <div style={{ padding: "30px" }}>{this.renderForm(loginTab)}</div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInitiate: state.loginReducer.loginInitiate,
    userAuth: state.loginReducer.userAuth,
  };
};

export default connect(mapStateToProps, {
  tryLogin,
  doSignup,
  closeLogin,
  getOTP,
  submitPasswordWithOTP,
})(LoginComponent);
