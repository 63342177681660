import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, Paper, Grid, Button } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";

import { Link } from "react-router-dom";

import SharerComponent from "../CommonComponents/SharerComponent";

import { getStudentProfile } from "./actions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import campaign_media from "../../resources/images/userAvatar.png";

class Profile extends Component {
  state = {
    linkSharer: false,
  };

  componentDidMount() {
    this.props.getStudentProfile(this.props.match.params.id);
  }

  render() {
    const { userAuth, profile } = this.props;
    const { linkSharer } = this.state;
    return (
      <div className="account-container">
        <SharerComponent
          open={linkSharer}
          onClose={() => {
            this.setState({ linkSharer: false });
          }}
          link={"/studentprofile/" + (profile ? profile.id : "")}
        />
        <Paper className="account-form">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="h4">Profile</Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}></Grid>
            <Grid item xs={12} style={{ margin: "auto", maxWidth: "900px" }}>
              {profile && (
                <>
                  <Paper className="form-field-row" variant="outlined">
                    <Grid container>
                      <Grid item xs={6} style={{ padding: "30px" }}>
                        <Typography variant={"h5"}>
                          {profile.firstname} {profile.lastname}
                        </Typography>
                        <Typography variant={"subtitle1"}>
                          {profile.institute}
                        </Typography>
                        {userAuth.user && userAuth.user.id && (
                          <Typography variant={"body2"}>
                            {profile.email}
                          </Typography>
                        )}
                        <br />
                        <br />
                        <Button variant="contained" color="primary">
                          Chat
                        </Button>{" "}
                        &nbsp;
                        {profile.available_for_funding && (
                          <>
                            <Link
                              to={"/publiccampaign/" + profile.collection_id}
                            >
                              <Button variant="contained" color="secondary">
                                Visit Campaign
                              </Button>
                            </Link>
                            &nbsp;{" "}
                          </>
                        )}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            this.setState({ linkSharer: true });
                          }}
                        >
                          Share
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ textAlign: "right", padding: "10px" }}
                      >
                        <img
                          src={
                            profile.avatar_media_url
                              ? profile.avatar_media_url
                              : campaign_media
                          }
                          height="200px"
                          alt={profile.firstname}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className="form-field-row" variant="outlined">
                    <Grid container>
                      <Grid item xs={12} style={{ padding: "20px" }}>
                        <Typography variant="h5">About Me</Typography>
                        <div
                          contentEditable="true"
                          dangerouslySetInnerHTML={{
                            __html: draftToHtml(profile.profile),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className="form-field-row" variant="outlined">
                    <Grid container>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                          variant="h5"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            padding: "20px",
                          }}
                        >
                          Resume
                        </Typography>
                        {profile.resume_url ? (
                          <embed
                            src={profile.resume_url}
                            view="FitH"
                            width="100%"
                            height="1125px"
                          ></embed>
                        ) : (
                          <Typography variant="h6">
                            Resume Not available
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.loginReducer.userAuth,
    profile: state.profileReducer.profile,
  };
};

export default connect(mapStateToProps, {
  getStudentProfile,
})(Profile);
