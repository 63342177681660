import React from "react";
import { Paper, Typography } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import investor_guide from "../../resources/images/investor_guide.jpg";

export default function (props) {
  return (
    <div>
      <div
        style={{
          height: "92vh",
          overflow: "hidden",
          backgroundImage: `url(${investor_guide})`,
          backgroundSize: "cover",
          backgroundPositionY: "-70px",
        }}
        onClick={() => {
          var elem = document.getElementById("faq_section");
          elem.scrollIntoView({ block: "start", behavior: "smooth" });
        }}
      >
        <Typography
          variant="h3"
          style={{
            paddingTop: "35vh",
            fontFamily: "fantasy",
            color: "white",
            textShadow: "3px 2px 1px black",
            textAlign: "center",
          }}
        >
          Impact Investor Guide
          <br />
        </Typography>
        <div
          style={{
            maxWidth: "74px",
            margin: "auto",
            cursor: "pointer",
            borderRadius: "50%",
            textAlign: "center",
            color: "white",
            padding: "20px",
            background: "rgb(50,50,50, 0.4)",
            boxShadow: "1px 0px 2px grey",
          }}
        >
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Paper id="faq_section" style={{ margin: "30px", padding: "50px" }}>
        <Typography variant="h4">IMPACT INVESTING</Typography>
        <Typography variant="body1">
          Impact investing refers to investments made with the intention to
          generate a measurable, beneficial social or environmental impact
          alongside a financial return". Impact investments provide capital to
          address social and/or environmental issues.
          <br />
          Alumna connects impact investors to a sector which is experiencing
          huge challenges from a financial inclusion and capacity constraints
          perspective. All over the world students are struggling and standing
          up against historical exclusion from education due to socio economic
          factors. Impact investors could make a huge social impact in
          transforming this sector for better socio economic development in
          local and emerging economies while gaining a higher return on
          investment and creating a big social impact.
          <br />
          Impact investments are investments made with the intention to generate
          positive, measurable social and environmental impact alongside a
          financial return.
          <br />
           Impact investments are known to bring in returns that are competitive
          with the stock market. According to a study by the Global Impact
          Investing Network (GIIN), impact investments have average returns of
          5.8% since their inception. The impact investment market is estimated
          at over 750 billion dollars in total size. Alumna creates a platform
          to access an avenue towards the impact investing sector through global
          education markets.
          <br />
        </Typography>
        <br />
        <br />
        <Typography variant="h4">What is a Blockchain?</Typography>
        <Typography variant="body1">
          It is decentralised technology that allows financial transactions
          between two participants in a secure, reliable and irreversible way,
          without having to go through intermediaries. Absolutely every movement
          that occurs is saved with this technology in a public database. Since
          it is public, you can access any of these movements, although they are
          100% anonymous and no identities of involved users can be retrieved.
          The recorded data is impossible to modify and can not be deleted
          either, which makes the Blockchain itself safe against manipulation.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">What is Ethereum?</Typography>
        <Typography variant="body1">
          Ethereum is a decentralized global platform, and the value of Ethereum
          lies in the fact that it enables one to write codes to control money
          and build applications that can be accessed from anywhere in the
          world. Ethereum is an open software platform that is based on the
          principles of blockchain technology. The main advantage of Ethereum is
          that it allows developers to build and deploy decentralized
          applications. Ethereum helps developers create operations based on
          their needs, and this means that developers can build thousands of
          different applications. Using Ethereum, developers can program their
          smart contracts. Smart contracts are self-executing contracts where
          terms of the agreement between the buyer and seller are directly
          written into lines of code. Another advantage of Ethereum is the smart
          contract system it offers that has certain significant technological
          implications. The code that is written on an Ethereum blockchain
          cannot be hacked, tampered, or altered. This feature ensured by
          cryptography is an interesting application of blockchain technology.
          Ethereum makes use of nodes to replace individual cloud systems and
          servers owned by major internet providers. These nodes are run by
          volunteers. The idea is for these nodes to connect to become a “world
          computer.” This would help provide infrastructure to people across the
          globe. An idealized Ethereum model is one that would be less
          vulnerable to hacks and shutdowns, as no entity will have control over
          your personal data.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">What is Ether?</Typography>
        <Typography variant="body1">
          Ether is a digital asset bearer similar to a commodity and is the
          solution to the issue of payment. Ether functions like cash in the
          sense that it does not require a third party for approving or
          processing the transactions. But Ether is not exactly a digital
          currency. It can be considered as a fuel for the apps on the
          decentralized Ethereum network. Ether is the incentive provided to
          developers and miners to keep the Ethereum network efficient and safe.
          Ether is not infinite. Though it is a computer program, it cannot be
          replicated infinitely. Ether is the fuel used to power the
          Ethereum network. Ethereum is a programmable blockchain-based software
          platform. Ether is the cryptocurrency asset that runs the Ethereum
          network. Ethereum has various applications, whereas Ether only has one
          application, that is, to enable operations on the blockchain.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">What is a Smart Contract?</Typography>
        <Typography variant="body1">
          A "SmartContract" is a software program that facilitates, ensures,
          enforces and executes agreements registered between two or more
          parties (for example people or organisations). As such they would
          assist them in negotiating and defining such agreements that will
          cause certain actions to occur as a result of a series of specific
          conditions being met.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">
          Why do we use Blockchain technology?
        </Typography>
        <Typography variant="body1">
          Blockchain technology gives transparency and allows access and
          traceability to all transactions, which helps to be able to follow
          them until the end of the process. It is like a publicly available
          ledger, where all transactions are recorded. However it is not
          possible see the real identity of other users in this data base.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">What means peer-to-peer or P2P ?</Typography>
        <Typography variant="body1">
          It is a direct connection between two parties. In P2P one person makes
          the transaction directly to the other without intermediaries. This is
          what happens on the Alumna platform: the investor sends the investment
          directly to the wallet of the platform member that requests the
          transaction.{" "}
        </Typography>
        <br />
        <br />
        <Typography variant="h4">
          What Happens When Impact Investors Buy into Impact Projects
        </Typography>
        <Typography variant="body1">
          The detailed flow and terms for project stakes are completely
          articulated in the terms of service when impact investors buy into
          projects and through the project stake owner agreement. Impact
          investors buy stakes into the crowd sale projects which are featured
          on the Alumna platform. New crowd sale projects are always featured on
          the platform as earlier projects become sold or reach term of
          expiration. Impact investors are defined as buyers of impact projects
          who extract back annuity income returns from project stakes and also
          support in social impact by supporting unbanked students graduate and
          become cash generating assets to support their local communities.
          <br />
          <br />
          The project stake owner agreement is sent to impact investors after
          they transact on the platform, this explanation is meant for summation
          purposes. <br />
          <br />
          After impact investors buy into project stakes their contributions are
          stored in 3rd party escrow accounts and nano wallets for secure and
          safe storage. They are stored until crowdsale projects are sold out or
          when the contributions reach the effective date to be transferred to
          beneficiaries (lessees) for usage of the digital asset. Impact
          investors effectively lease out their ownership of value in project
          stakes on the network infrastructure to beneficiaries (students).{" "}
          <br />
          <br />
          Proceeds from crowd-sales will be transferred relative to transfer of
          value for students towards global learning institutions and subsequent
          requirements. It must be noted that students will be vetted by
          Alumna’s specialised vetting team with decades of experience in
          specialised credit and through Alumna’s governance processes. Alumna’s
          team and governance processes will be the control mechanism to make
          sure that impact investors get their rental payments (annuity income)
          when students become cash generating assets upon graduation. Alumna
          also focuses on approving students for the digital lease contracts
          which are primarily focused on students undertaking STEM
          qualifications (Science, Technology, Engineering and Mathematics).
          Alumna focuses on STEM qualification students due to the need to
          support growth in the digital technology industry and job prospects in
          the next decade and to support local economic development. STEM
          qualifications will not be the only technical skills supported but
          Alumna will use intuitive governance processes in examining all
          applications for varying qualifications to support unbanked students
          become productive (cash generating assets). Alumna has a risk
          management process that could be requested for further detail on the
          requirements and qualifying criteria.
          <br />
          <br />
          The ethereum network infrastructure is the digital asset whereas ether
          is the digital currency attached to the ethereum infrastructure
          network. Alumna may transfer the value of the project stakes in
          digital currency or fiat money dependant on the collection preference
          of the education institutions. The ethereum network allows for the
          transfer of value and is the building connecting the application layer
          community.
          <br />
          <br />
          Alumna acts as a leasing agent to connect the community on the
          infrastructure network and platform. Alumna is not a financial
          services provider but acts as a leasing agent between digital impact
          investors and students/learners.
          <br />
          <br />
          Once project stake value is transferred to qualifying students through
          institutions there will be a period whereby supported students who
          extract value through learning institutions will be allowed to acquire
          technical skills till graduation. This will be described as the lease
          moratorium period. Upon graduation when students become cash
          generating assets they will commence paying rental fees back to the
          project stakes they extracted value from. The project stakes run for a
          minimum of 36 months of annuity rental payments.
        </Typography>
      </Paper>
    </div>
  );
}
