import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField, Button, Grid } from "@material-ui/core";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const SignupForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name="firstName"
            component={renderTextField}
            label="Username/Email"
            type="text"
            placeholder="First Name"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="lastName"
            component={renderTextField}
            label="Username/Email"
            type="text"
            placeholder="Last Name"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email"
            component={renderTextField}
            label="Username/Email"
            type="email"
            placeholder="Email"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
          >
            Submit
          </Button> &nbsp;
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "signupForm", // a unique identifier for this form
})(SignupForm);
