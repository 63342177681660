import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid } from "@material-ui/core";
import { renderTextField } from "../../../utils/reduxFormComponents";

const LoginForm = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="username"
            component={renderTextField}
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <input type="checkbox" /> Remember Me{" "}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {" "}
          <span
            style={{ cursor: "pointer", color: "#45ad7e" }}
            onClick={() => props.onForgotPasswordClick()}
          >
            Forgot Password{" "}
          </span>
        </Grid>
        <Grid item xs={6}>
          {/* <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={pristine || submitting}
            onClick={reset}
            fullWidth
          >
            Clear Values
          </Button> */}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || submitting}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "LoginForm", // a unique identifier for this form
})(LoginForm);
