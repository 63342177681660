import React from "react";
import {
  TextField,
  TextareaAutosize,
  Select,
  FormHelperText,
  Switch,
  Button,
} from "@material-ui/core";
import { Publish as PublishIcon } from "@material-ui/icons";
import AsyncSelect from "react-select/async";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    floatingLabelText={label}
    error={touched && !!error}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const renderTextAreaField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextareaAutosize
    label={label}
    floatingLabelText={label}
    error={touched && !!error}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <>
    <Select
      labelId={"simple-select-helper-label" + label}
      id={"simple-select-helper" + label}
      {...input}
      onChange={(event) => {
        input.onChange(event.target.value);
      }}
      children={children}
      {...custom}
    ></Select>
    {error && <FormHelperText>{error}</FormHelperText>}
  </>
);

const renderSwitchField = ({ input, meta: { touched, error }, ...rest }) => (
  <>
    <Switch
      checked={input.value}
      error={touched && !!error}
      helperText={touched && error}
      onChange={(event) => input.onChange(event.target.checked)}
      {...rest}
    />
    {error && <FormHelperText>{error}</FormHelperText>}
  </>
);

const uploadFileField = ({ input, meta: { touched, error }, ...rest }) => {
  return (
    <div>
      {/* <input type="file" accept="*" onChange={props.input.onChange} /> */}
      <Button component="label" color="inherit">
        {input.value
          ? typeof input.value === "string"
            ? input.value
            : input.value[0].name
          : "Upload File"}
        <input type="file" hidden accept="*" onChange={input.onChange} />
        <PublishIcon />
      </Button>
    </div>
  );
};

const autoCompleteSelect = ({
  input,
  loadOptions,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onChange={(value) => {
        input.onChange(value);
      }}
      {...rest}
      // inputValue={
      //   typeof input.value == "string" && input.value !== ""
      //     ? input.value
      //     : typeof input.value !== "object"
      //     ? null
      //     : input.value
      // }
    />
  );
};

export {
  renderTextField,
  renderTextAreaField,
  renderSelectField,
  renderSwitchField,
  uploadFileField,
  autoCompleteSelect,
};
