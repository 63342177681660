import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, Grid, Typography, Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";

import CardImage from "../../../resources/images/campaigncard.jpg";
// TODO
// Put all css in style file
const useStyles = makeStyles({
  rootCardContainer: {
    margin: "10px",
    padding: "10px",
    height: "100%",
  },
});

function sumTotalFunding(transactions) {
  return transactions.reduce((total, obj) => {
    if (obj.status === "confirmed") {
      return total + obj.value;
    } else {
      return total;
    }
  }, 0);
}

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function CampaignCard(props) {
  const classes = useStyles();
  const { campaign } = props;
  const totalFunding = sumTotalFunding(campaign.collection);

  return (
    <Paper className={classes.rootCardContainer}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div
            style={{
              backgroundImage: `url(${
                campaign.media ? campaign.media : CardImage
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "24vh",
              borderRadius: "3px",
            }}
          />
          {/* <img src={campaign.media} width={"100%"} alt={campaign.title} /> */}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ minHeight: "80px", maxHeight: "80px", overflow: "hidden" }}
        >
          <Typography variant={"body1"}>
            <b>
              {
                campaign.title
                // .length
                //  > 27
                //   ? campaign.title.slice(0, 27).concat("...")
                //   : campaign.title
              }
            </b>
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={12}
          style={{ minHeight: "6vh", maxHeight: "6vh", overflow: "hidden" }}
        >
          <Typography variant={"caption"}>
            {campaign.description.length > 70
              ? campaign.description.slice(0, 70).concat("...")
              : campaign.description}
          </Typography>
        </Grid> */}
      </Grid>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ color: "grey", paddingLeft: "10px" }}>
            <Typography variant="caption">Target: {campaign.goal}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ color: "grey", textAlign: "right", paddingRight: "10px" }}
          >
            <Typography variant="caption">Invested: {totalFunding}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className="text-center"
              variant="outlined"
              style={{
                padding: "5px 20px",
                margin: "auto",
                maxWidth: "300px",
              }}
            >
              <Typography>Goal Progress</Typography>
              <PrettoSlider
                max={campaign.goal}
                value={totalFunding}
                step={null}
              />
            </Paper>
          </Grid>
          <Grid item xs={4} style={{ color: "grey", paddingLeft: "10px" }}>
            <Typography variant="caption">
              Days:{" "}
              {Math.round(
                (new Date(campaign.end_date).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ color: "grey", textAlign: "right", paddingRight: "10px" }}
          >
            <Typography variant="caption">
              Project Stake Sold:{" "}
              {((totalFunding / campaign.goal) * 100).toFixed(2)}%
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Link to={"/publiccampaign/" + campaign.id}>
              <Button variant="contained" color="secondary">
                Learn More &nbsp; <SendIcon />
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
