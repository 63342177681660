import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Grid, MenuItem, InputLabel } from "@material-ui/core";
import {
  renderTextField,
  renderSelectField,
} from "../../../utils/reduxFormComponents";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstname",
    "lastname",
    "email",
    "user_type",
    "password",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  if (values.password && values.confirmPassword !== values.password) {
    errors.confirmPassword = "Password doesn't match";
  }
  return errors;
};

const SignupForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {" "}
          <InputLabel id="simple-select-label" style={{ paddingTop: "15px" }}>
            I am here to
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <Field
            name="user_type"
            component={renderSelectField}
            label="User Type"
            fullWidth
          >
            <MenuItem value={"student"}>Raise Funds for my Education</MenuItem>
            <MenuItem value={"investor"}>I am an Investor</MenuItem>
            <MenuItem value={"recruiter"}>Find talented individuals</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="firstname"
            component={renderTextField}
            label="First Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="lastname"
            component={renderTextField}
            label="Last Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email"
            component={renderTextField}
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="confirmPassword"
            component={renderTextField}
            label="Confirm Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={pristine || submitting}
            onClick={reset}
            fullWidth
          >
            Clear Values
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || submitting}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "SignupForm", // a unique identifier for this form
  validate,
})(SignupForm);
