import React, { Component } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  // ButtonGroup,
} from "@material-ui/core";
import { connect } from "react-redux";

import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";

import { getPublicCampaign, getStudentCampaigns } from "../HomePage/actions";

import image6 from "../../resources/lpc/6.jpg";

const defaultStudentCampaign = {
  id: 31,
  title: "Student Donation Campaign",
  description:
    "The campaign is meant for students to gain prizes, grants and scholarships towards student tuition and living expenses. Students who share their profiles will receive points and the money will be distributed to students as benefits to help in raising money towards studies.",
  goal: 50000,
  currency: "USD",
  start_date: "2021-01-31T18:30:00.000Z",
  end_date: "2021-04-30T18:30:00.000Z",
  media: "/v1/files/165",
  collection: [
    {
      value: null,
      currency: null,
      status: null,
      investor_id: null,
    },
  ],
};

class CampaignList extends Component {
  state = {
    defaultView: this.props.match.params.type
      ? this.props.match.params.type
      : "student",
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      defaultView: props.match.params.type
        ? props.match.params.type
        : "student",
    };
  }

  componentDidUpdate() {
    let { studentCampaigns, campaigns } = this.props;
    if (
      this.props.match.params.type === "public" &&
      campaigns &&
      campaigns.length === 0
    ) {
      this.props.getPublicCampaign();
    } else if (
      this.props.match.params.type === "student" &&
      studentCampaigns &&
      studentCampaigns.length === 0
    ) {
      this.props.getStudentCampaigns();
    }
  }

  componentDidMount() {
    if (this.props.match.params.type === "public") {
      this.props.getPublicCampaign();
    } else if (this.props.match.params.type === "student") {
      this.props.getStudentCampaigns();
    } else {
      this.props.getStudentCampaigns();
    }
  }

  toggleView = (view) => {
    const { defaultView } = this.state;
    this.setState({ defaultView: view });
    if (defaultView === "public") {
      this.props.getPublicCampaign();
    } else {
      this.props.getStudentCampaigns();
    }
  };

  campaignsCardList = (campaign) => {
    return (
      <Paper style={{ margin: "15px", padding: "15px" }}>
        <Link to={"/publiccampaign/" + campaign.id}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div
                style={{
                  backgroundImage: `url(${campaign.media})`,
                  width: "100%",
                  height: "18vh",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h6">{campaign.title}</Typography>
              <Typography
                variant="body1"
                style={{
                  minHeight: "7vh",
                  maxHeight: "7vh",
                  overflow: "hidden",
                }}
              >
                {campaign.description.length > 200
                  ? campaign.description.slice(0, 200).concat("...")
                  : campaign.description}
              </Typography>
              <Typography variant="body2">
                <b>Open Till</b>{" "}
                {new Date(campaign.end_date).toLocaleString("en-US")}
              </Typography>
              <Typography variant="body2">
                <b>Total Goal:</b> {campaign.currency} {campaign.goal}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <Button variant="contained" color="primary">
                Learn More &nbsp; <SendIcon />
              </Button>
            </Grid>
          </Grid>
        </Link>
      </Paper>
    );
  };

  render() {
    const { defaultView } = this.state;
    const { campaigns, studentCampaigns } = this.props;
    return (
      <div>
        {defaultView === "student" ? (
          <>
            <div
              style={{
                height: "92vh",
                overflow: "hidden",
                backgroundImage: `url(${image6})`,
                backgroundSize: "cover",
                backgroundPositionY: "-70px",
                textAlign: "center",
                color: "white",
                textShadow: "1px 1px 3px black",
              }}
            >
              <Typography
                variant="h2"
                style={{
                  padding: "30vh 50vh",
                  fontFamily: "fantasy",
                }}
                color="inherit"
              >
                Empower Talented Individuals all over the world.
              </Typography>
            </div>
            <Typography
              style={{
                // fontFamily: "fantasy",
                fontWeight: "100",
                padding: "40px 200px 0px",
                textAlign: "center",
              }}
              variant="h5"
              color="primary"
            >
              The student campaigns page allows investor members to see where
              their contributions are having an outreach. The student campaign
              page also allows Investor members to gift directly to student
              campaigns they would like to support as a donation without a
              return. Donate to the student campaign pool or donate directly to
              individuals.
            </Typography>
          </>
        ) : (
          ""
        )}
        <Paper style={{ padding: "30px", margin: "30px" }}>
          <Typography variant="h4">
            {defaultView === "public"
              ? "Ongoing Crowd Sale Projects"
              : "Ongoing Campaigns"}
          </Typography>
          <Typography variant="h6">
            {defaultView === "public"
              ? "Digital Asset Project Stakes"
              : defaultView.charAt(0).toUpperCase() +
                defaultView.slice(1) +
                " Campaigns"}
          </Typography>
          {/* <ButtonGroup style={{ margin: "20px" }} color="primary">
            <Button
              variant={defaultView === "public" ? "contained" : "outlined"}
              onClick={() => {
                this.toggleView("public");
              }}
            >
              Public
            </Button>
            <Button
              variant={defaultView === "student" ? "contained" : "outlined"}
              onClick={() => {
                this.toggleView("student");
              }}
            >
              Student
            </Button>
          </ButtonGroup> */}
          {defaultView === "public" ? (
            campaigns.map((item) => this.campaignsCardList(item))
          ) : (
            <>
              <div style={{ border: "solid 1px orange" }}>
                {this.campaignsCardList(defaultStudentCampaign)}
              </div>
              {studentCampaigns.map((item) => this.campaignsCardList(item))}
            </>
          )}

          {defaultView === "student" && (
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "5px" }}
            >
              <Link to="/publiccampaign/31">
                <Button variant="contained" color="secondary">
                  Donate to Student Pool
                </Button>
              </Link>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.homeReducer.pageFeed,
    studentCampaigns: state.homeReducer.studentCampaigns,
  };
};

export default connect(mapStateToProps, {
  getPublicCampaign,
  getStudentCampaigns,
})(CampaignList);
