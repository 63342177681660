import { GET_MY_CAMPAIGN } from "../../constants";

import axios from "axios";
import { toast } from "react-toastify";

export const createCampaign = (data) => {
  return (dispatch) => {
    axios
      .post("/studentcampaign/campaign", data, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(getMyCampaign());
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const getMyCampaign = () => {
  return (dispatch) => {
    axios
      .get("/studentcampaign/my")
      .then((res) => {
        dispatch({ type: GET_MY_CAMPAIGN, data: res.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};

export const updateCampaign = (data) => {
  return (dispatch) => {
    axios
      .patch("/studentcampaign/updatecampaign", data, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(getMyCampaign());
        toast.info("Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
};
