import React, { useState } from "react";
import {
  Typography,
  Grid,
  Slider,
  Paper,
  Fab,
  TextField,
} from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import { months } from "../../../utils/isoList";

function sumTotalFunding(transactions) {
  return transactions.reduce((total, obj) => {
    if (obj.status === "confirmed") {
      return total + obj.value;
    } else {
      return total;
    }
  }, 0);
}

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function CampaignComponent(props) {
  const { campaign, onPayClick } = props;
  const totalFunding = sumTotalFunding(campaign.collection);
  const [goalMarker, setGoalMarker] = useState([
    {
      value: totalFunding,
      label: "Total",
    },
  ]);
  //   const
  return (
    <>
      <Paper
        className="form-field-row"
        variant="outlined"
        style={{ padding: "15px" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" style={{ textTransform: "uppercase" }}>
              {campaign.title}
            </Typography>
            <div>
              <hr style={{ marginRight: "20px", color: "black" }} />
              <Typography variant="h6" component="span">
                <b>Investment Target:</b>
                &nbsp;
                {campaign.currency}&nbsp;
                {campaign.goal}
              </Typography>
            </div>
            <div>
              {campaign.repayment_date && (
                <>
                  <Typography variant="h6" component="span">
                    <b>Repayment Commencement: </b>
                    {months[new Date(campaign.repayment_date).getMonth()]}{" "}
                    {new Date(campaign.repayment_date).getFullYear()}
                  </Typography>
                  <br />
                </>
              )}
              {campaign.interest && (
                <>
                  <Typography variant="subtitle1" component="span">
                    <b>IRR: Estimated up to {campaign.interest}%</b>
                  </Typography>
                  <br />
                </>
              )}
              {campaign.annuity_earning_in_years && (
                <>
                  <Typography variant="subtitle1" component="span">
                    <b>
                      {campaign.annuity_earning_in_years} year (
                      {campaign.annuity_earning_in_years * 12} month) annuity
                      earnings
                    </b>
                  </Typography>
                  <br />
                </>
              )}
              <Typography variant="h6" component="span">
                Campaign Start:{" "}
                <b>
                  {new Date(campaign.start_date).toISOString().substring(0, 10)}
                </b>
              </Typography>
              <br />
              <Typography variant="h6" component="span">
                Active till:{" "}
                <b>
                  {new Date(campaign.end_date).toISOString().substring(0, 10)}
                </b>
              </Typography>
            </div>
            <Paper
              className="text-center"
              variant="outlined"
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                maxWidth: "300px",
              }}
            >
              <Typography>Campaign Status</Typography>
              <PrettoSlider
                max={campaign.goal}
                value={
                  goalMarker.length > 1
                    ? [totalFunding, goalMarker[1].value]
                    : totalFunding
                }
                step={null}
                marks={goalMarker}
                getAriaValueText={(v) => {
                  return campaign.currency + " " + v;
                }}
              />
            </Paper>
          </Grid>
          <Grid
            className="text-center"
            item
            xs={6}
            style={{
              backgroundImage: `url(${campaign.media})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "center",
              height: "auto",
              overflow: "hidden",
            }}
          ></Grid>
        </Grid>
      </Paper>

      <Paper
        className="form-field-row"
        variant="outlined"
        style={{ padding: "20px" }}
      >
        <Grid container spacing={3}>
          {campaign.youtube_id && (
            <Grid className="text-center" item xs={4}>
              <iframe
                title="campaign media"
                width="100%"
                height="315"
                src={
                  "https://www.youtube.com/embed/" +
                  campaign.youtube_id.split("?v=")[1]
                }
              ></iframe>
            </Grid>
          )}
          <Grid
            className="text-center"
            item
            xs={campaign.youtube_id ? 8 : 12}
            style={{ textAlign: "left", whiteSpace: "pre-line" }}
          >
            <Typography variant="h5">Description</Typography>
            <br />
            {campaign.description}
          </Grid>
          {campaign.chart_image && (
            <Grid
              item
              xs={12}
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              <Paper
                style={{
                  padding: "10px",
                  width: "max-content",
                  margin: "auto",
                }}
              >
                <img src={campaign.chart_image} alt="chart" width="750px" />
              </Paper>
            </Grid>
          )}
          {onPayClick ? (
            <Grid className="text-center" item xs={12}>
              <Paper
                style={{
                  maxWidth: "210px",
                  margin: "auto",
                  paddingTop: "10px",
                }}
              >
                <Typography>Support the campaign</Typography>
                <span>{campaign.currency}</span>
                <TextField
                  id="payment-amount"
                  type="number"
                  placeholder="Amount"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: Number(campaign.goal) - Number(totalFunding),
                    },
                  }}
                  style={{
                    maxWidth: "75px",
                    margin: "10px",
                    input: { color: "white" },
                  }}
                  onChange={(e) => {
                    let allowedFunding =
                      Number(campaign.goal) - Number(totalFunding);
                    if (Number(e.target.value) > allowedFunding) {
                      e.target.value = allowedFunding > 0 ? allowedFunding : 0;
                    }
                    let goalMakerS = [...goalMarker];
                    goalMakerS[1] = {
                      value: Number(totalFunding) + Number(e.target.value),
                      label: "Your",
                    };
                    setGoalMarker(goalMakerS);
                  }}
                />
                <Fab
                  size="medium"
                  color="primary"
                  disabled={
                    !(
                      goalMarker.length > 1 &&
                      Number(
                        Number(goalMarker[1].value) - Number(totalFunding)
                      ) > 0
                    )
                  }
                  onClick={() => {
                    if (goalMarker.length > 1) {
                      onPayClick(
                        Number(goalMarker[1].value) - Number(totalFunding)
                      );
                      document.getElementById("payment-amount").value = "";
                    }
                  }}
                >
                  <SendIcon />
                </Fab>
              </Paper>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Paper>
    </>
  );
}

export default CampaignComponent;
