import React, { Component } from "react";
import { connect } from "react-redux";

import { getHomePageData } from "./actions";

class Login extends Component {
  componentDidMount() {
    this.props.getHomePageData();
  }
  render() {
    return (
    <div>
        <h1>Login</h1>
        {
            this.props.pageFeed.map(item => {
                return "Email: " + item.email + "\n"
            })
        }
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    pageFeed: state.homeReducer.pageFeed
  };
};

export default connect(mapStateToProps, {
  getHomePageData,
})(Login);
