import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField, Button, Grid } from "@material-ui/core";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const LoginForm = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          name="username"
          component={renderTextField}
          label="Username/Email"
          type="text"
          placeholder="Username/Email"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          component={renderTextField}
          label="Password"
          type="password"
          placeholder="Password"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={pristine || submitting}
        >
          Submit
        </Button>
      </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(LoginForm);
