import React, { Component } from "react";
import { connect } from "react-redux";

import { Paper, Grid } from "@material-ui/core";

import { getHomePageData } from "./actions";

import LoginForm from "./components/loginForm";
import SignupForm from "./components/signupForm";

import "./style.css";

class Login extends Component {
  componentDidMount() {
    this.props.getHomePageData();
  }
  render() {
    return (
      <div className="login-container">
        <Paper>
          <div className="main">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper className="login" elevation={0}>
                  <h2>LOGIN</h2>
                  <LoginForm />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className="signup" elevation={0}>
                  <h2>SIGNUP</h2>
                  <SignupForm />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageFeed: state.homeReducer.pageFeed,
  };
};

export default connect(mapStateToProps, {
  getHomePageData,
})(Login);
