// src/components/StudentInfo/reducer.js
import {FUNDING_ENQUIRY_SUCCESS, FUNDING_ENQUIRY_FAIL, TOGGLE_FUNDING_FROM} from '../../constants';


export default (state = { submitted: false, error: null, isFormOpen: false }, action) => {
  switch (action.type) {
    case TOGGLE_FUNDING_FROM:
      return { ...state, isFormOpen: !state.isFormOpen };
    case FUNDING_ENQUIRY_SUCCESS:
      return { ...state, submitted: true, error: null, isFormOpen: !state.isFormOpen };
    case FUNDING_ENQUIRY_FAIL:
      return { ...state, submitted: false, error: action.payload, isFormOpen: !state.isFormOpen };
    default:
      return state;
  }
};
