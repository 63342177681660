import { MY_ACCOUNT } from "../../constants";

let initialState = {
  myAccount: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MY_ACCOUNT:
      return { ...state, myAccount: action.data.data };
    default:
      return state;
  }
};
