import React from "react";
import { connect } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

import hiwbg from "../../resources/images/hiwbg.jpg";

import { getPublicCampaign } from "../../container/HomePage/actions";

import CampaignCard from "../../container/HomePage/components/CampaignCard";

class HowItWorks extends React.Component {
  componentDidMount() {
    this.props.getPublicCampaign();
    window.addEventListener("scroll", this.handleScroll);
    // let videoElement = document.getElementById("introVideo");
    // videoElement.play();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    let videoElement = document.getElementById("introVideo");
    if (
      window.pageYOffset >= videoElement.offsetTop - 500 &&
      window.pageYOffset <= videoElement.offsetTop + videoElement.offsetHeight
    ) {
      if (videoElement.paused) {
        videoElement.play();
        // videoElement.muted = false;
      }
    } else {
      videoElement.pause();
    }
  };

  render() {
    const { pageFeed } = this.props;
    pageFeed.length = 4;
    return (
      <div style={{ textAlign: "center", marginBottom: "50px" }}>
        <div
          style={{
            marginBottom: "100px",
            height: "92vh",
            overflow: "hidden",
            backgroundImage: `url(${hiwbg})`,
            backgroundSize: "cover",
            backgroundPositionY: "-70px",
          }}
        >
          <Typography
            variant="h3"
            style={{
              maxWidth: "780px",
              margin: "auto",
              paddingTop: "30vh",
              fontFamily: "fantasy",
              color: "white",
              textShadow: "3px 2px 1px black",
            }}
          >
            Invest in people, invest in talent and invest in global education
            for a great return and positive social impact.
          </Typography>
        </div>
        <Typography variant="h4">
          JOIN THE GLOBAL HIGHER EDUCATION REVOLUTION
        </Typography>
        <Typography variant="h6" style={{ padding: "10px 150px 10px 150px" }}>
          "EDUCATION IS THE MOST POWERFUL WEAPON YOU CAN USE TO CHANGE THE
          WORLD."
          <br /> - NELSON MANDELA
        </Typography>

        <Link to="/campaigns/public">
          <Button variant="contained" color="primary">
            {" "}
            Invest Now{" "}
          </Button>
        </Link>
        <br />
        <video
          id="introVideo"
          muted="muted"
          preload="auto"
          style={{ maxWidth: "78%", marginTop: "30px", marginBottom: "30px" }}
          onClick={() => {
            let videoElement = document.getElementById("introVideo");
            videoElement.muted = !videoElement.muted;
          }}
          controls
        >
          <source
            src="https://alumna-pub.s3.af-south-1.amazonaws.com/media/alumna_short_HDv2.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML video.
        </video>
        <Typography
          variant="h5"
          color="primary"
          style={{ maxWidth: "150vh", margin: "auto", padding: "10px 150px" }}
        >
          Start monetising education with the click of a button starting at only
          $10 with your credit card or digital currency
        </Typography>
        <div style={{ textAlign: "left" }}>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={
              window.innerHeight > 700
                ? window.innerHeight / 5.38
                : window.innerHeight / 5.38 + window.innerHeight / 27
            }
            totalSlides={pageFeed.length}
            isPlaying={false}
            visibleSlides={4}
            interval={10000}
            hasMasterSpinner={pageFeed.length === 0}
          >
            <Slider style={{ padding: "30px" }}>
              {pageFeed.map((item, i) => {
                return (
                  <Slide ref={i} key={i}>
                    <CampaignCard campaign={item} />
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </div>
        <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
          <Link to="/campaigns/public">
            <Button variant="contained" color="primary">
              Browse Crowd Sale Projects
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageFeed: state.homeReducer.pageFeed,
  };
};

export default connect(mapStateToProps, {
  getPublicCampaign,
})(HowItWorks);
